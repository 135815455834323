import { columnBuilder } from 'utils/helpers';
import { Row, createColumnHelper } from '@tanstack/react-table';
import {
  Flexbox,
  Icons,
  RadioButton,
  Sizes,
  SelectionCheckbox,
  Button,
  Variants
} from '@sede-x/shell-ds-react-framework';
import OverviewColumns from './overviewColumns.json';
import ActionColumns from './actionColumns.json';

export function getOverviewColumns(value: string) {
  return columns[value as keyof typeof columns];
}

export const columnHelper = createColumnHelper();

export const expandAction = [
  columnHelper.display({
    id: 'expandAction',
    size: 20,
    cell: ({ row }) => (
      <Button
        aria-label="Expander button"
        size={Sizes.Small}
        variant={Variants.Transparent}
        icon={
          row.getIsExpanded() ? (
            <Icons.ChevronDown data-testid="expander-expanded" />
          ) : (
            <Icons.ChevronRight data-testid="expander-collapsed" />
          )
        }
        iconOnly
        onClick={() => row.toggleExpanded()}
      />
    ),
    header: ''
  })
];

export const detailAction = (onClick: (row: Row<unknown>) => void) => [
  columnHelper.display({
    id: 'expandAction',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        <Button
          size={Sizes.Small}
          variant={Variants.Transparent}
          icon={
            <Icons.ChevronRight
              className="cursor-pointer"
              data-testid={`details-${row.index}`}
            />
          }
          iconOnly
          onClick={() => onClick(row)}
        />
      </Flexbox>
    ),
    header: ''
  })
];

export const radioactions = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        <RadioButton
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
          data-testid={`rowcheck-${row.index}`}
          size={Sizes.ExtraSmall}
        />
      </Flexbox>
    ),
    header: ''
  })
];

const actions = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        <SelectionCheckbox
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
          data-testid={`rowcheck-${row.index}`}
        />
      </Flexbox>
    ),
    header: ({ table }) => (
      <Flexbox gap="12px" alignItems="center">
        <SelectionCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      </Flexbox>
    )
  })
];

const Customer = [...columnBuilder(OverviewColumns.Customer)];

const TollStatus = [...columnBuilder(OverviewColumns.TollStatus)];

const Vehicle = [...columnBuilder(OverviewColumns.Vehicle)];
const CustomerVehicles = [...columnBuilder(OverviewColumns.CustomerVehicles)];

const Card = [...columnBuilder(OverviewColumns.Card)];

const Contract = [...columnBuilder(OverviewColumns.Contract)];

const OBU = [...columnBuilder(OverviewColumns.OBU)];
const CustomerOBU = [...columnBuilder(OverviewColumns.CustomerOBU)];

const Contact = [...columnBuilder(OverviewColumns.Contact)];

const Addresses = [...columnBuilder(OverviewColumns.Address)];

const AdditionalAccounts = [
  ...radioactions,
  ...columnBuilder(OverviewColumns.AdditionalAccounts)
];

const Contracts = [...columnBuilder(OverviewColumns.Contracts)];
const Emission = [...columnBuilder(OverviewColumns.Emission)];
const Fuel = [...columnBuilder(OverviewColumns.Fuel)];
const OfferType = [...columnBuilder(OverviewColumns.FranceContractOfferType)];

const Comments = [...columnBuilder(OverviewColumns.Comments)];
const zipCode = [...radioactions, ...columnBuilder(OverviewColumns.ZipCode)];
const Logs = [...columnBuilder(OverviewColumns.Logs)];

const CardUsage = [...columnBuilder(OverviewColumns.CardUsage)];
const CardList = [...columnBuilder(OverviewColumns.CardList)];

const obuTollList = [
  ...radioactions,
  ...columnBuilder(OverviewColumns.ObuTollList)
];

const obuContractList = [
  ...radioactions,
  ...columnBuilder(OverviewColumns.obuContract)
];

const obuVehicleList = [
  ...radioactions,
  ...columnBuilder(OverviewColumns.obuVehicle)
];

const DocumentTab = [...columnBuilder(OverviewColumns.DocumentTab)];

const OBUContractSelection = [
  ...radioactions,
  ...columnBuilder(OverviewColumns.OBUContractSelection)
];
const OBUAddressesSelection = [
  ...radioactions,
  ...columnBuilder(OverviewColumns.OBUAddressesSelection)
];
const OBUSelection = [
  ...actions,
  ...columnBuilder(OverviewColumns.OBUSelection)
];
const ServiceOverview = [...columnBuilder(OverviewColumns.ServiceOverview)];

const OBUServiceModification = columnBuilder(
  OverviewColumns.OBUServiceModification
);

const OBUReplacementAddress = [
  ...columnBuilder(OverviewColumns.OBUReplacementAddress)
];
const OBUProduct = [
  ...radioactions,
  ...columnBuilder(OverviewColumns.OBUProduct)
];

const T4EVehicleProduct = [
  ...actions,
  ...columnBuilder(OverviewColumns.OBUProduct)
];

const t4eTollDiscount = [
  ...actions,
  ...columnBuilder(OverviewColumns.t4eTollDiscount)
];

const DeliveryAddress = [
  ...radioactions,
  ...columnBuilder(OverviewColumns.DeliveryAddress)
];
const DeliveryProduct = [
  ...radioactions,
  ...columnBuilder(OverviewColumns.DeliveryProduct)
];
const VehicleList = [...actions, ...columnBuilder(OverviewColumns.VehicleList)];
const VehicleListPersonalize = [
  ...radioactions,
  ...columnBuilder(OverviewColumns.VehicleList)
];
const OBUProductPersonalize = [
  ...radioactions,
  ...columnBuilder(OverviewColumns.OBUProductPersonalize)
];
const OBUProductDepersonalize = [
  ...radioactions,
  ...columnBuilder(OverviewColumns.OBUProductDepersonalize)
];
const ContractDetailsDepersonalize = [
  ...radioactions,
  ...columnBuilder(OverviewColumns.ContractDetailsDepersonalize)
];
const OBUAdditionalCustomers = [
  ...radioactions,
  ...columnBuilder(ActionColumns.addExceptionsGetCustomers)
];

const T4EAddressesSelection = [
  ...columnBuilder(OverviewColumns.t4eCustomerAddress)
];

const T4ECardSelection = [
  ...radioactions,
  ...columnBuilder(OverviewColumns.t4eCustomerCard)
];

const T4ECustomerRequestHistory = [
  ...columnBuilder(OverviewColumns.t4eCustomerRequestHistory)
];

const T4ECustomerRequestHistoryDetails = [
  ...columnBuilder(OverviewColumns.t4eCustomerRequestHistoryDetails)
];

const T4EVehicleRequestHistory = [
  ...columnBuilder(OverviewColumns.t4eVehicleRequestHistory)
];

const T4EVehicleRequestHistoryDetails = [
  ...columnBuilder(OverviewColumns.t4eVehicleRequestHistoryDetails)
];

const T4EOBURequestHistory = [
  ...columnBuilder(OverviewColumns.t4eOBURequestHistory)
];

const T4EOBURequestHistoryParcelDetails = [
  ...columnBuilder(OverviewColumns.t4eOBURequestHistoryParcelDetails)
];

export const columns = {
  Customer,
  TollStatus,
  Comments,
  Logs,
  Vehicle,
  CustomerVehicles,
  Card,
  Contract,
  CustomerOBU,
  OBU,
  Contact,
  Addresses,
  AdditionalAccounts,
  Contracts,
  Emission,
  Fuel,
  OfferType,
  CardUsage,
  CardList,
  zipCode,
  obuTollList,
  obuContractList,
  obuVehicleList,
  DocumentTab,
  OBUContractSelection,
  OBUAddressesSelection,
  OBUSelection,
  ServiceOverview,
  OBUServiceModification,
  OBUReplacementAddress,
  OBUProduct,
  DeliveryAddress,
  DeliveryProduct,
  VehicleList,
  OBUProductPersonalize,
  VehicleListPersonalize,
  OBUProductDepersonalize,
  ContractDetailsDepersonalize,
  OBUAdditionalCustomers,
  T4EAddressesSelection,
  T4ECardSelection,
  T4EVehicleProduct,
  t4eTollDiscount,
  T4ECustomerRequestHistory,
  T4ECustomerRequestHistoryDetails,
  T4EVehicleRequestHistory,
  T4EVehicleRequestHistoryDetails,
  T4EOBURequestHistory,
  T4EOBURequestHistoryParcelDetails
};
