import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { useFormikContext } from 'formik';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import { useState, useEffect } from 'react';
import { customerEndPoints } from 'api/apiEndpoints';
import { ColumnDef } from '@tanstack/react-table';
import { STALE_TIME } from 'react-query';
import { SaveOBUOrderDto, DeliveryProductDto } from './types';

const DEFAULT_PAGE_SIZE = 20;
interface DeliveryProductProps {
  countryTypeID?: string;
}

async function fetchDeliveryProduct(
  countryTypeID: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post(customerEndPoints.getDeliveryProduct, {
    countryTypeID,
    pageNumber,
    pageSize
  });
}

const DeliveryProduct: React.FC<DeliveryProductProps> = ({ countryTypeID }) => {
  const { setFieldValue } = useFormikContext<SaveOBUOrderDto>();
  const [selectedRows, setSelectedRows] = useState<DeliveryProductDto[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      'get-type-products-by-country',
      countryTypeID,
      pageSize,
      pageNumber
    ],
    queryFn: () =>
      fetchDeliveryProduct(countryTypeID as string, pageNumber, pageSize).then(
        (res) => res.data
      ),
    staleTime: STALE_TIME,
    refetchOnWindowFocus: 'always'
  });

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);

  useEffect(() => {
    if (selectedRows.length) {
      const row = selectedRows[0];
      setFieldValue('deliveryProductTypeID', row.productTypeID);
      setFieldValue('deliveryProduct', row.name);
    } else {
      setFieldValue('deliveryProductTypeID', undefined);
      setFieldValue('deliveryProduct', undefined);
    }
  }, [selectedRows]);

  return (
    <div
      className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
      data-testid="obu-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
        <Table<DeliveryProductDto>
          data={data?.data ?? []}
          columns={
            getOverviewColumns(
              'DeliveryProduct'
            ) as ColumnDef<DeliveryProductDto>[]
          }
          columnSelection={false}
          exportEnabled={false}
          enableMultiRowSelection={false}
          onSelectedRowsChange={setSelectedRows}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
        />
      </QueryError>
    </div>
  );
};

export default DeliveryProduct;
