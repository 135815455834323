import {
  Table,
  TableWrapper,
  Pagination,
  Popover
} from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 10;
  padding: 5px;
`;

export const StyledTableWrapper = styled(TableWrapper)`
  border: 2px solid rgba(0, 0, 0, 0.15);
`;

export const SDSTable = styled(Table)`
  width: 100%;
  td,
  th {
    border-left: 2px solid rgba(0, 0, 0, 0.15);
  }
  td:first-child,
  th:first-child {
    border-left: none;
  }
`;

export const StyledPagination = styled(Pagination)`
  .shell-pagination-item {
    min-width: 32px;
    width: auto !important;
    padding: 0 10px;
  }
`;

export const StyledPopover = styled(Popover)`
  z-index: 5;
  overflow: hidden auto;
  max-height: 150px;
`;

export const SDSTableExpandable = styled(Table)`
  background-color: transparent;
  width: 100%;
  td {
    background-color: #fff;
  }
  th {
    background-color: #f7f7f7;
  }
  tr {
    height: 69px;
  }
`;

export const RowGap = styled.tr`
  height: 3px !important;
  td {
    height: 3px;
    background-color: #f7f7f7;
  }
`;

export const ExpandableTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  // flex-grow: 10;
  padding: 20px;
`;
