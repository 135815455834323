import { Tooltip } from '@sede-x/shell-ds-react-framework';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

interface TableTooltipProps {
  message?: string;
  children: React.ReactNode;
}

const Overlay = styled.span`
  display: block;
  padding: 4px 8px;
  max-width: 60vw;
`;

const TableTooltip: React.FC<TableTooltipProps> = ({ message, children }) => {
  if (message) {
    return (
      <Tooltip
        overlay={<Overlay>{parse(message)}</Overlay>}
        trigger={children as ReactElement}
      />
    );
  }
  return <>{children}</>;
};

export default TableTooltip;
