import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import QueryError from 'components/QueryError';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import Table from 'components/Table/Table';
import { FormikValues, useFormikContext } from 'formik';
import { forwardRef, useEffect, useState } from 'react';
import { ColumnDef, RowSelectionState } from '@tanstack/react-table';
import { ProductsRef, TollDiscount } from './types';

async function fetchTollDiscount() {
  return customerInstance.post(t4eEndpoints.TollDiscount);
}

const TollDiscounts = forwardRef<ProductsRef>((_props) => {
  const { setFieldValue } = useFormikContext<FormikValues>();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const { data, isLoading, isError } = useQuery({
    queryKey: ['t4e-toll-discounts'],
    queryFn: () => fetchTollDiscount().then((res) => res.data),
    refetchOnWindowFocus: 'always'
  });

  const tollDiscounts = data?.data ?? [];

  // default select all rows
  useEffect(() => {
    if (tollDiscounts?.length) {
      const selectedRows = tollDiscounts.map(
        (row: TollDiscount) => row.discountID
      );
      const defaultRowSelection: RowSelectionState = {};
      selectedRows.forEach((rowId: string) => {
        defaultRowSelection[rowId] = true;
      });
      setRowSelection(defaultRowSelection);
    }
  }, [tollDiscounts]);

  useEffect(() => {
    if (Object.keys(rowSelection).length) {
      // get names of selected rows and set it to formik field
      const selecteDiscountIds = Object.keys(rowSelection);

      // find name of selected rows from tollDiscounts
      const selectedRows = tollDiscounts.filter((row: TollDiscount) =>
        selecteDiscountIds.includes(row.discountID)
      );
      setFieldValue(
        'discountNames',
        selectedRows.map((row: TollDiscount) => row.name)
      );
      setFieldValue('selectedDiscounts', selectedRows);
    } else {
      setFieldValue('discountNames', undefined);
      setFieldValue('selectedDiscounts', undefined);
    }
  }, [rowSelection]);

  return (
    <div
      className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
      data-testid="t4e-customer-tollDiscount-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
        <Table<TollDiscount>
          data={tollDiscounts}
          columns={
            getOverviewColumns('t4eTollDiscount') as ColumnDef<TollDiscount>[]
          }
          columnSelection={false}
          exportEnabled={false}
          rowSelectionState={rowSelection}
          setRowSelectionState={setRowSelection}
          stickyColumns={['actions']}
          getRowId={(row) => row.discountID}
        />
      </QueryError>
    </div>
  );
});

export default TollDiscounts;
