import React, { ReactNode } from 'react';
import { StyledTabs } from './Styles';

interface TabItem {
  key: string;
  label: string;
  children: ReactNode;
}

interface TabsProps {
  items: TabItem[];
  sideElements?: ReactNode;
  onChange?: (activeKey: string) => void;
  className?: string;
}

const Tabs: React.FC<TabsProps> = ({
  items,
  sideElements,
  onChange,
  className
}) => (
  <StyledTabs
    prefixCls="uirefresh-tabs"
    items={items}
    defaultActiveKey={items[0].key}
    renderTabBar={(tabNavBarProps, TabNavList) => (
      <div className="flex flex-row  items-center bg-white ">
        <TabNavList {...tabNavBarProps} />
        <div className="ml-auto">{sideElements}</div>
      </div>
    )}
    onChange={onChange}
    className={className}
  />
);
export default Tabs;
