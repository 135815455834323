import React, { ReactNode, useEffect, useState } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import { useQuery } from '@tanstack/react-query';
import QueryError from 'components/QueryError';
import { BaseTable, Card, Sizes, Text } from '@sede-x/shell-ds-react-framework';
import { createMap } from 'utils/helpers';
import { VehicleProductsDto } from '../../../Customer/T4ECustomerRegistration/components/types';
import { ObuProductDto, SummeryMap } from './types';

const DEFAULT_MESSAGE = {
  suspend:
    'The vehicle does not have any service from the list above to be suspended',
  terminate:
    'The vehicle does not have any service from the list above to terminate',
  restore: 'No changes will be applied.'
};

interface SummaryProps {
  customerId: string;
  type: string;
  endpoint?: string;
  showService?: boolean;
}

const BoldText = ({ children }: { children: ReactNode }) => (
  <span className="font-bold">{children}</span>
);

const productColumns = [
  { header: 'Product', accessorKey: 'name' },
  { header: 'External Contract Number', accessorKey: 'externalContractNumber' }
];

const getSummary = async (
  customerId: string,
  endpoint: string,
  values: FormikValues
) => {
  const { selectedObus, products } = values;

  const payload = {
    customerId,
    obus: selectedObus.map((obu: ObuProductDto) => ({
      obuId: obu.id,
      obuSerialNo: obu.obuSerialNumber
    })),
    productValues: products.map((product: VehicleProductsDto) => product.value)
  };
  return customerInstance.post(endpoint, payload);
};

const Summary: React.FC<SummaryProps> = ({
  customerId,
  type,
  endpoint = t4eEndpoints.getSummaryTollProduct,
  showService = true
}) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const [summaryMap, setSummaryMap] = useState<SummeryMap>({});
  const { selectedObus, products } = values;

  const {
    data: summaryData,
    isLoading,
    isError
  } = useQuery({
    queryKey: [endpoint, customerId, ...selectedObus, ...products],
    queryFn: () =>
      getSummary(customerId, endpoint, values).then((res) => res.data),
    enabled: !!customerId,
    retry: false,
    cacheTime: 0
  });

  useEffect(() => {
    if (summaryData?.data) {
      setSummaryMap(
        createMap(summaryData.data, 'obuId') as unknown as SummeryMap
      );
    }
  }, [summaryData]);

  useEffect(() => {
    if (isError || isLoading) {
      setFieldValue('isValidToProceedForSave', false);
    } else {
      setFieldValue('isValidToProceedForSave', true);
    }
  }, [isError, isLoading]);

  return (
    <QueryError isError={isError} isLoading={isLoading}>
      <div className="flex flex-col grow overflow-y-auto p-5 bg-shellExtraPaleGrey2">
        <div className="flex">
          <Text>
            <BoldText>Result</BoldText>
          </Text>
        </div>
        <div className=" flex flex-col gap-4 p-6">
          {products && showService && (
            <Card
              header={{ title: 'Selected Services' }}
              size={Sizes.Small}
              bodyBgColor
            >
              {products.map((product: VehicleProductsDto) => (
                <Text size={Sizes.Small} key={product.value}>
                  {product.name}
                </Text>
              ))}
            </Card>
          )}
          {selectedObus && (
            <Card
              header={{ title: 'Selected Obus' }}
              size={Sizes.Small}
              bodyBgColor
            >
              <div className="flex gap-3 flex-col">
                {selectedObus.map((obu: ObuProductDto) => (
                  <Card
                    key={obu.id}
                    header={{
                      description: `${obu.obuProductType} - ${obu.obuSerialNumber} assigned to ${obu.vehicleRegistrationNumber}`
                    }}
                  >
                    <div className="flex gap-5 flex-col">
                      {!summaryMap[obu.id] && (
                        <Text size={Sizes.Small}>
                          {
                            DEFAULT_MESSAGE[
                              type as keyof typeof DEFAULT_MESSAGE
                            ]
                          }
                        </Text>
                      )}

                      {summaryMap[obu.id] && (
                        <BaseTable
                          columns={productColumns}
                          data={summaryMap[obu.id].tollProducts}
                          size={Sizes.Small}
                        />
                      )}
                    </div>
                  </Card>
                ))}
              </div>
            </Card>
          )}
        </div>
      </div>
    </QueryError>
  );
};

export default Summary;
