import { Text } from '@sede-x/shell-ds-react-framework';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import { FormikValues, useFormikContext } from 'formik';
import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle
} from 'react';
import QueryError from 'components/QueryError';
import { saveAs } from 'file-saver';
import { base64toBlob } from 'utils/helpers';
import { VehicleListDto } from '../components/types';

async function fetchVehiclePackDocuments(vehicleIds: string[]) {
  return customerInstance.post(
    t4eEndpoints.downloadVehiclePackDeclarationDocument,
    {
      vehicleIds
    }
  );
}

const BoldText = ({ children }: { children: ReactNode }) => (
  <span className="font-bold">{children}</span>
);

const Summary = forwardRef((_props, ref) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const vehicleIds = values?.vehicles?.map(
    (vehicle: VehicleListDto) => vehicle.vehicleId
  );

  const { data, isLoading, isError } = useQuery({
    queryKey: ['vehiclePackDocuments', ...vehicleIds],
    queryFn: () =>
      fetchVehiclePackDocuments(vehicleIds).then((res) => res.data),
    enabled: !!vehicleIds.length,
    retry: false
  });

  const { data: fileInformations, messages } = data || {};

  useEffect(() => {
    if (isError || !fileInformations) {
      setFieldValue('error', true);
    } else {
      setFieldValue('error', false);
    }
  }, [fileInformations, isError]);

  useImperativeHandle(ref, () => ({
    download: () => {
      const file = base64toBlob(
        fileInformations?.fileContents,
        'application/zip'
      );

      saveAs(file, fileInformations?.fileDownloadName);
    }
  }));

  return (
    <div className="flex flex-col grow overflow-y-auto p-5 bg-shellExtraPaleGrey2">
      <QueryError
        isLoading={isLoading}
        isError={isError}
        errorText="Exception occurred during package creation."
      >
        <div className="flex">
          <Text>
            <BoldText>The Package will contain following files:</BoldText>
          </Text>
        </div>
        <div>
          <div>{fileInformations?.fileDownloadName}</div>
          {messages?.map((message: string) => (
            <div key={message}>{message}</div>
          ))}
        </div>
      </QueryError>
    </div>
  );
});

export default Summary;
