import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { DocumentsEndpoints } from 'api/apiEndpoints';
import QueryError from 'components/QueryError';
import { saveAs } from 'file-saver';
import { ExpandableTable } from 'components/Table/ExpandableTable';
import {
  getOverviewColumns,
  radioactions
} from 'components/Table/columns/overviewColumns';
import React, { useState } from 'react';
import { queryClient } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import { Button, Icons } from '@sede-x/shell-ds-react-framework';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { errorHelper } from 'utils/helpers/errorHelper';
import documentTypes from 'utils/StaticData/Customer/documentTypes.json';
import { useAuth } from 'auth/Authorization';
import { DocumentDto } from './types';
import DocumentNew from './DocumentNew/DocumentNew';

interface DocumentsProps {
  type: string;
  id: string;
  colcoCountryTypeId?: string;
}

async function fetchDocuments(type: string, id: string) {
  const payload = type === 'vehicle' ? { vehicleID: id } : { customerId: id };
  const { data } = await customerInstance.post(
    DocumentsEndpoints.get[type],
    payload
  );
  return data;
}

const Documents: React.FC<DocumentsProps> = ({
  type,
  id,
  colcoCountryTypeId
}) => {
  const { verifyRoles, roles } = useAuth();
  const [selectedRow, setSelectedRow] = useState<DocumentDto[] | null>(null);

  const { errorDialog, successDialog, confirmCloseDialog } =
    useConfirmDialogs();

  const { isError, isLoading, data } = useQuery({
    queryKey: ['Documents', id, type],
    queryFn: () => fetchDocuments(type, id),
    cacheTime: 0,
    refetchOnWindowFocus: 'always',
    enabled: !!id
  });

  const { data: documentsData } = data || {};

  const handleDelete = async () => {
    const [document] = selectedRow ?? [];

    const isConfirmed = await confirmCloseDialog(
      'Delete Document',
      'Are you sure you want to delete the selected Document?'
    );
    if (!isConfirmed) {
      return;
    }
    const payload = {
      documentId: document.documentId,
      documentTypeId: document.documentTypeId,
      storageId: document.storageId,
      fileName: document.name,
      fileExtension: document?.name?.split('.')[1]
    };
    customerInstance
      .post(DocumentsEndpoints.delete[type], payload)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['Documents']
        });
        successDialog('', 'Document deleted successfully');
      })
      .catch((error) => {
        const message = 'Failed to delete document.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSelectedRow(null);
      });
  };

  const handleDownload = () => {
    const [document] = selectedRow ?? [];

    const payload = {
      documentId: document.documentId,
      documentTypeId: document.documentTypeId,
      storageId: document.storageId
    };

    customerInstance
      .post(DocumentsEndpoints.download[type], payload)
      .then((res) => {
        const base64String = res.data?.data?.fileContents;

        // Remove the data URL prefix if present
        const byteCharacters = atob(base64String);
        const byteNumbers = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const file = new Blob([byteNumbers]);

        saveAs(file, document.name);
      })
      .catch((error) => {
        const message =
          'Your download request has been failed. Please try again.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSelectedRow(null);
      });
  };

  const matchingTypeNames = documentsData?.map(
    (item: { typeName: unknown }) => item.typeName
  );

  const isPolandCustomer =
    colcoCountryTypeId === 'B345CF2A-6A3F-4D2E-A719-22E2817B3F08'.toLowerCase();
  const hiddendocumentsTypeIdForNonPolandCustomer = [
    '92aa7ccd-b2a4-4272-ad9e-21acdb66fc0d',
    'd9bf5cfa-690a-4423-befd-65af2ddba827'
  ];

  // Read all allowed document types for vehicle and poland customers
  const filteredDocumentTypes = documentTypes?.filter((doc) => {
    const filteredExistingDocuments =
      !matchingTypeNames?.includes(doc.Name) && doc.type === type;

    if (isPolandCustomer || doc.type === 'vehicle') {
      return filteredExistingDocuments;
    }
    // Non poland customers, Hide KRS and CEIDG documents
    return (
      filteredExistingDocuments &&
      !hiddendocumentsTypeIdForNonPolandCustomer.includes(doc.DocumentTypeID)
    );
  });

  return (
    <div className=" bg-white p-4 h-full">
      <div className="flex  flex-col h-full">
        <QueryError isLoading={isLoading} isError={isError}>
          <div className="flex gap-2 justify-end p-2">
            <div className="flex items-center gap-2">
              <DocumentNew
                type={type}
                id={id}
                filteredDocumentTypes={filteredDocumentTypes}
                disabled={verifyRoles([roles.SOTR_VIEW])}
              />
              <Button
                icon={<Icons.TrashClear />}
                size="xsmall"
                variant="transparent"
                onClick={handleDelete}
                disabled={
                  !selectedRow?.length ||
                  verifyRoles([roles.SOTR_VIEW, roles.SOTR_MANAGE])
                }
                style={{
                  display: !documentsData?.length ? 'none' : ''
                }}
                data-testid="delete-document"
              >
                Delete
              </Button>
              <Button
                icon={<Icons.Download />}
                size="xsmall"
                variant="transparent"
                onClick={handleDownload}
                disabled={
                  !selectedRow?.length || verifyRoles([roles.SOTR_VIEW])
                }
                style={{
                  display: !documentsData?.length ? 'none' : ''
                }}
                data-testid="download-document"
              >
                Download
              </Button>
            </div>
          </div>
          <div className="bg-[#F0F0F0] h-full pb-3 overflow-y-auto flex flex-col">
            <ExpandableTable<DocumentDto>
              data={documentsData}
              columns={
                verifyRoles([roles.SOTR_VIEW])
                  ? ([
                      ...getOverviewColumns('DocumentTab')
                    ] as ColumnDef<DocumentDto>[])
                  : ([
                      ...radioactions,
                      ...getOverviewColumns('DocumentTab')
                    ] as ColumnDef<DocumentDto>[])
              }
              stickyColumns={['actions']}
              columnSelection={false}
              exportEnabled={false}
              onRowClick={(row) => {
                if (verifyRoles([roles.SOTR_VIEW])) {
                  return;
                }
                setSelectedRow([row.original]);
              }}
              enableMultiRowSelection={false}
              selectedRows={{
                [selectedRow?.[0]?.documentId as string]: true
              }}
              getRowId={(row) => row.documentId as string}
            />
          </div>
        </QueryError>
      </div>
    </div>
  );
};

export default Documents;
