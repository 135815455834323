import { FormikErrors } from 'formik';
import { Vehicle } from '../types';

const MANDATORY_MESSAGE = 'Please fill out this mandatory field';
const MAX_WEIGHT = 65535;

const validateVehicleWeight = (values: Vehicle) => {
  let errors: FormikErrors<Vehicle> = {};

  // weight should be between 1 - 65535
  if (
    !values.grossVehicleWeightRating &&
    values.grossVehicleWeightRating !== 0
  ) {
    errors.grossVehicleWeightRating = MANDATORY_MESSAGE;
  } else if (
    values.grossVehicleWeightRating < 1 ||
    values.grossVehicleWeightRating > MAX_WEIGHT
  ) {
    errors.grossVehicleWeightRating =
      'Vehicle weight(F.2) must be between 1 and 65535';
  }

  // The authorized gross vehicle weight cannot be smaller than the gross vehicle weight!
  if (
    !values.authorizedGrossVehicleWeight &&
    values.authorizedGrossVehicleWeight !== 0
  ) {
    errors.authorizedGrossVehicleWeight = MANDATORY_MESSAGE;
  } else if (
    values.authorizedGrossVehicleWeight < 1 ||
    values.authorizedGrossVehicleWeight > MAX_WEIGHT
  ) {
    errors.authorizedGrossVehicleWeight =
      'Authorized vehicle weight(F.3) must be between 1 and 65535';
  } else if (
    values.grossVehicleWeightRating &&
    values.authorizedGrossVehicleWeight &&
    values.grossVehicleWeightRating > values.authorizedGrossVehicleWeight
  ) {
    errors.authorizedGrossVehicleWeight =
      'Authorized vehicle weight(F.3) cannot be smaller than the gross vehicle weight';
  }

  if (!values.totalVehicleWeight && values.totalVehicleWeight !== 0) {
    errors.totalVehicleWeight = MANDATORY_MESSAGE;
  } else if (
    values.totalVehicleWeight < 1 ||
    values.totalVehicleWeight > MAX_WEIGHT
  ) {
    errors.totalVehicleWeight =
      'Total vehicle weight(G) must be between 1 and 65535';
  }

  errors = { ...errors, ...validateWeightF1AndWeightF2(values) };

  return errors;
};

const validateWeightF1AndWeightF2 = (values: Vehicle) => {
  const errors: FormikErrors<Vehicle> = {};

  // throw validation for weightF1 if its empty and it should accept zero also
  if (!values.weightF1 && values.weightF1 !== 0) {
    errors.weightF1 = MANDATORY_MESSAGE;
  } else if (values.weightF1 < 1 || values.weightF1 > MAX_WEIGHT) {
    errors.weightF1 = 'Vehicle weight(F.1) must be between 1 and 65535';
  }

  // A Trailer max laden weight must be empty or 0 when Trailer type is No trailer!
  if (!values.trailerMaxLadenWeight && values.trailerMaxLadenWeight !== 0) {
    errors.trailerMaxLadenWeight = MANDATORY_MESSAGE;
  } else if (
    values.trailerMaxLadenWeight < 0 ||
    values.trailerMaxLadenWeight > MAX_WEIGHT
  ) {
    errors.trailerMaxLadenWeight =
      'Trailer Max. laden weight(F.2) must be between 0 and 65535';
  } else if (
    values.trailerTypeID &&
    values?.trailerTypeID?.toLocaleLowerCase() ===
      '52670d54-ecd1-4db7-8424-0313cff23d7e' &&
    values.trailerMaxLadenWeight !== 0
  ) {
    errors.trailerMaxLadenWeight =
      'Trailer Max. laden weight(F.2) must be empty or 0 when Trailer type is No trailer';
  }

  return errors;
};

function checkVinCode(vinCode?: string) {
  const pattern = /^([A-HJ-NP-Z\d]{17})$/;
  const errors: FormikErrors<Vehicle> = {};
  const MAX_VIN_LENGTH = 17;
  const INCORRECT_MESSAGE = 'Incorrect format of the VIN code';

  if (!vinCode) {
    errors.vin = 'Please fill out this mandatory field';
    return errors;
  }

  // 1. first check the length - VIN has 17 characters
  if (vinCode.length !== MAX_VIN_LENGTH) {
    errors.vin = 'Incorrect number of characters (less or more than 17)';
    return errors;
  }

  // 2. VIN cannot be consisted exclusively from numbers or letters
  if (isExclusivelyNumeric(vinCode) || isExclusivelyLiteral(vinCode)) {
    errors.vin = INCORRECT_MESSAGE;
    return errors;
  }

  // 3. first 3 characters cannot be numbers only
  const FIRST_THREE_CHARACTERS = 3;
  const wmi = vinCode.substring(0, FIRST_THREE_CHARACTERS);
  if (isExclusivelyNumeric(wmi)) {
    errors.vin = INCORRECT_MESSAGE;
    return errors;
  }

  // 4. check the format with regex
  const match = pattern.test(vinCode.toUpperCase());
  if (!match) {
    errors.vin = INCORRECT_MESSAGE;
    return errors;
  }

  return errors;
}

function isExclusivelyNumeric(str: string) {
  return /^\d+$/.test(str);
}

function isExclusivelyLiteral(str: string) {
  return /^[A-Za-z]+$/.test(str);
}

export const validateVehicleDetails = (values: Vehicle) => {
  let errors: FormikErrors<Vehicle> = {};

  const MANDATORY_FIELDS = [
    'carRegistration',
    'cleanLicensePlate',
    'firstDayOfRegistration',
    'countryTypeID',
    'vehicleTypeID',
    'categoryTypeID',
    'fuelTypeID',
    'emissionTypeID',
    'vehicleModelTypeID',
    'trailerTypeID'
  ];
  MANDATORY_FIELDS.forEach((field) => {
    if (
      !values[field as keyof Vehicle] ||
      String(values[field as keyof Vehicle]).trim() === '' ||
      values[field as keyof Vehicle] === null
    ) {
      errors[field as keyof Vehicle] = MANDATORY_MESSAGE;
    }
  });

  // check truckTractorAxlesNumber is between 1 to 10
  if (!values.truckTractorAxlesNumber && values.truckTractorAxlesNumber !== 0) {
    errors.truckTractorAxlesNumber = MANDATORY_MESSAGE;
  } else if (
    values.truckTractorAxlesNumber < 1 ||
    values.truckTractorAxlesNumber > 10
  ) {
    errors.truckTractorAxlesNumber =
      'Truck axles number must be between 1 and 10';
  }

  if (!values.trailerAxlesNumber && values.trailerAxlesNumber !== 0) {
    errors.trailerAxlesNumber = MANDATORY_MESSAGE;
  } else if (values.trailerAxlesNumber < 0 || values.trailerAxlesNumber > 10) {
    errors.trailerAxlesNumber = 'Trailer axles number must be between 0 and 10';
  }

  errors = {
    ...errors,
    ...validateVehicleWeight(values),
    ...checkVinCode(values.vin)
  };

  return errors;
};
