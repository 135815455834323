import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import QueryError from 'components/QueryError';
import {
  columnHelper,
  detailAction,
  getOverviewColumns
} from 'components/Table/columns/overviewColumns';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { ColumnDef, Row } from '@tanstack/react-table';
import {
  Alignments,
  Button,
  Flexbox,
  Modal,
  Sentiments,
  Sizes
} from '@sede-x/shell-ds-react-framework';
import { Drawer } from 'components/Drawer/Drawer';
import {
  CustomerRequestDto,
  ObuRequestDto,
  RequestHistoryRef,
  VehicleRequestDto
} from './types';
import RequestDetails from './RequestDetails';
import ParcelDetails from './ParcelDetails';

interface RequestHistoryProps {
  customerId: string;
  type?: string;
}

type RequestHistoryDataType =
  | CustomerRequestDto
  | VehicleRequestDto
  | ObuRequestDto;

const DEFAULT_PAGE_SIZE = 20;

const Container = styled.div`
  > :last-of-type {
    margin-top: auto;
  }
`;

const endPoint = {
  customer: t4eEndpoints.customerRequestHistory,
  vehicle: t4eEndpoints.vehicleRequestHistory,
  obu: t4eEndpoints.obuRequestHistory
};

const getTableColumns = (type: string) => {
  switch (type) {
    case 'customer':
      return getOverviewColumns('T4ECustomerRequestHistory');
    case 'vehicle':
      return getOverviewColumns('T4EVehicleRequestHistory');
    case 'obu':
      return getOverviewColumns('T4EOBURequestHistory');
    default:
      return [];
  }
};

async function fetchRequestHistory(
  customerId: string,
  type: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post(endPoint[type as keyof typeof endPoint], {
    customerId,
    pageNumber,
    pageSize
  });
}

export const parcelDetailAction = (
  onClick: (row: Row<ObuRequestDto>) => void
) => [
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        <Button
          size={Sizes.ExtraSmall}
          onClick={() => onClick(row as Row<ObuRequestDto>)}
          disabled={!(row.original as ObuRequestDto).hasParcel}
          className="w-full"
          data-testid={`parcel-details-${row.index}`}
        >
          View
        </Button>
      </Flexbox>
    ),
    header: 'Parcel Detail'
  })
];

const RequestHistory = forwardRef<RequestHistoryRef, RequestHistoryProps>(
  ({ customerId, type = 'customer' }, ref) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [openParcelDetails, setOpenParcelDetails] = useState(false);
    const [selectedOBURequest, setSelectedOBURequest] =
      useState<ObuRequestDto | null>(null);

    const [openDetails, setOpenDetails] = useState(false);
    const [selectedRequest, setSelectedRequest] =
      useState<RequestHistoryDataType | null>(null);

    const { data, isLoading, isError, refetch } = useQuery({
      queryKey: ['t4e-CustomerRequest', customerId, pageNumber, pageSize, type],
      queryFn: () =>
        fetchRequestHistory(customerId, type, pageNumber, pageSize).then(
          (res) => res.data
        ),
      refetchOnWindowFocus: false
    });

    const handleChangePagination = (
      newPageNumber: number,
      newPageSize: number
    ) => {
      setPageNumber(newPageNumber);
      setPageSize(newPageSize);
    };

    const handleClickDParcelDetails = (row: Row<ObuRequestDto>) => {
      setOpenParcelDetails(true);
      setSelectedOBURequest(row.original);
    };

    const handleOnCloseParcelDetails = () => {
      setOpenParcelDetails(false);
      setSelectedOBURequest(null);
    };

    const paginationProps = useSdsPagination(handleChangePagination);

    const parcelButton = parcelDetailAction(handleClickDParcelDetails);

    const columns = getTableColumns(
      type
    ) as ColumnDef<RequestHistoryDataType>[];

    useImperativeHandle(ref, () => ({
      refetchData() {
        refetch();
      }
    }));

    const handleClickDetails = (row: Row<unknown>) => {
      setSelectedRequest(row.original as RequestHistoryDataType);
      setOpenDetails(true);
    };

    const handleOnClose = () => {
      setOpenDetails(false);
      setSelectedRequest(null);
    };

    const detailIcons = detailAction(handleClickDetails);

    return (
      <>
        <Container
          className="flex flex-col grow overflow-y-auto gap-2 pb-3 bg-shellExtraPaleGrey2 h-full "
          data-testid="request-History-list"
        >
          <QueryError isLoading={isLoading} isError={isError}>
            <Table<RequestHistoryDataType>
              data={data?.data ?? []}
              columns={
                type === 'obu'
                  ? ([
                      ...detailIcons,
                      ...columns,
                      ...parcelButton
                    ] as ColumnDef<RequestHistoryDataType>[])
                  : ([
                      ...detailIcons,
                      ...columns
                    ] as ColumnDef<RequestHistoryDataType>[])
              }
              columnSelection={false}
              exportEnabled={false}
              enableRowSelection={false}
              enableMultiRowSelection={false}
              paginationData={{
                ...paginationProps,
                total: data?.count
              }}
              stickyColumns={['expandAction']}
              selectedRows={{
                [selectedRequest?.requestHistoryId as string]: true
              }}
              getRowId={(row) => row.requestHistoryId}
              meta={{
                getRowStyles: (row) => ({
                  border: row.getIsSelected()
                    ? '3px solid rgb(251, 206, 7)'
                    : ''
                }),
                getRowProps: (row) => ({
                  ...(row.original.status === 'Error' && {
                    sentiment: Sentiments.Negative
                  })
                })
              }}
            />
          </QueryError>
        </Container>
        {openParcelDetails && selectedOBURequest && (
          <Modal
            title="Parcel Delivery Status"
            open
            width="85%"
            size={Sizes.Small}
            maskClosable={false}
            mask
            onClose={handleOnCloseParcelDetails}
            contentScrollable
            actionsAlignment={Alignments.Right}
            centered
            prefixCls="parcel-details-modal"
          >
            <div>
              <ParcelDetails
                requestHistoryId={selectedOBURequest.requestHistoryId}
              />
            </div>
          </Modal>
        )}

        <Drawer
          open={openDetails}
          header="Request Details"
          onClose={handleOnClose}
          width="80%"
          bodyPadding={false}
          sticky
          size={Sizes.Small}
          mask={false}
          getContainer={false}
        >
          <RequestDetails
            customerId={customerId}
            requestHistoryId={selectedRequest?.requestHistoryId ?? ''}
            type={type}
          />
        </Drawer>
      </>
    );
  }
);

export default RequestHistory;
