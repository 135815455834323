import {
  Alignments,
  Prominences,
  Sizes,
  Text,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import {
  Field,
  Formik,
  FormikHelpers,
  FormikProps,
  FormikValues
} from 'formik';
import { StyledModal } from 'Pages/CustomerDetails/components/Actions/styles';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import React, { useRef, useState } from 'react';
import { errorHelper } from 'utils/helpers/errorHelper';
import SelectField from 'components/FormCmponents/SelectField';
import { TypeVehicleDocumentEnum } from 'utils/constants/constants';
import { VehicleListDto } from '../components/types';
import VehicleList from './components/VehicleList';
import Summary from './components/Summary';

interface GetT4EDocumentProps {
  CustomerId: string;
  customerName: string;
  handleOnCloseModal: () => void;
}

const STEP_3 = 3;
const TOTAL_STEPS = 3;

const getTitles = (step: number) => {
  switch (step) {
    case 1:
      return 'Document Type';
    case 2:
      return 'Vehicles';
    case TOTAL_STEPS:
      return 'Summary';
    default:
      return '';
  }
};

const documentTypes = [
  TypeVehicleDocumentEnum.Vehicle_Declaration_document,
  TypeVehicleDocumentEnum.Vehicle_France_Barcode_document,
  TypeVehicleDocumentEnum.Vehicle_Scandinavia_Declaration_document
];

const GetT4EDocument: React.FC<GetT4EDocumentProps> = ({
  CustomerId,
  customerName,
  handleOnCloseModal
}) => {
  const { errorDialog, successDialog } = useConfirmDialogs();
  const [step, setStep] = useState<number>(1);
  const formRef = useRef<FormikProps<FormikValues>>(null);

  const renderTitle = () => (
    <>
      <div className="flex flex-col space-y-4 pl-2">
        <Text size={Sizes.Small}>Get T4E Document wizard</Text>
        <Text prominence={Prominences.Strong} bold size={Sizes.Medium}>
          {getTitles(step)}
        </Text>
      </div>

      <Text className="pl-2" size="small">
        {customerName}
      </Text>
    </>
  );

  const handleOnStepClick = (num: number, _isBackClick = false) => {
    setStep(num);
  };

  const handleClose = () => {
    handleOnCloseModal();
    setStep(1);
  };

  const handleOnClickBack = (stepNum: number) => {
    if (stepNum === STEP_3) {
      formRef.current?.setFieldValue('vehicles', undefined);
    }
  };

  const nextDisabled = (values: FormikValues) => {
    if (step === 1) {
      return !values.documentType;
    }
    if (step === 2) {
      return !values.vehicles;
    }
    return false;
  };

  const handleSave = (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    const { setSubmitting } = formikHelpers;

    const { vehicles, documentType } = values;

    const data = {
      customerId: CustomerId,
      vehicleIds: vehicles.map((vehicle: VehicleListDto) => vehicle.vehicleId),
      documentType
    };

    customerInstance
      .post(t4eEndpoints.saveDeclarationDocument, {
        ...data
      })
      .then(() => {
        successDialog('', 'Successfully inserted your request into processing');

        handleOnCloseModal();
        setStep(1);
      })
      .catch((error) => {
        const message = 'Failed to save the request.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      onSubmit={handleSave}
      innerRef={formRef}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
        <StyledModal
          title={renderTitle()}
          open
          width="90%"
          size={Sizes.Small}
          maskClosable={false}
          mask
          onClose={handleClose}
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          actions={[
            {
              label: 'CANCEL',
              action: () => {
                handleOnCloseModal();
              },
              props: {
                variant: Variants.Outlined
              }
            },
            {
              label: 'BACK',
              action: () => {
                handleOnClickBack(step);
                handleOnStepClick(step - 1, true);
              },
              props: {
                variant: Variants.Outlined,
                hidden: step === 1,
                style: {
                  display: step === 1 ? 'none' : ''
                }
              }
            },
            {
              label: 'NEXT',
              action: () => {
                handleOnStepClick(step + 1);
              },
              props: {
                disabled: nextDisabled(values),
                hidden: step === TOTAL_STEPS,
                style: {
                  display: step === TOTAL_STEPS ? 'none' : ''
                }
              }
            },
            {
              label: 'FINISH',
              action: () => {
                handleSubmit();
              },
              props: {
                disabled: isSubmitting,
                type: 'submit',
                hidden: step !== TOTAL_STEPS,
                style: {
                  display: step !== TOTAL_STEPS ? 'none' : ''
                },
                'aria-hidden': step !== TOTAL_STEPS
              }
            }
          ]}
        >
          {step === 1 && (
            <div className="p-2  bg-shellExtraPaleGrey2 h-full ">
              <Field
                id="documentType"
                name="documentType"
                label="Select Document Type"
                labelPosition="left"
                placeholder="Select"
                mandatory
                size={Sizes.Small}
                component={SelectField}
                options={documentTypes.map((type) => ({
                  label: type.name,
                  value: type.key
                }))}
                onChange={(value: string) => {
                  setFieldValue('documentType', value);
                  // set document type name
                  const selectedDocumentType = documentTypes.find(
                    (type) => type.key === value
                  )?.name;
                  setFieldValue('documentTypeName', selectedDocumentType);
                }}
              />
            </div>
          )}
          {step === 2 && (
            <VehicleList
              customerId={CustomerId}
              documentType={values.documentType}
            />
          )}
          {step === TOTAL_STEPS && <Summary title="Result" />}
        </StyledModal>
      )}
    </Formik>
  );
};

export default GetT4EDocument;
