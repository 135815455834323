import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { useFormikContext, FormikValues } from 'formik';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import { useState, useEffect } from 'react';
import { customerEndPoints } from 'api/apiEndpoints';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Flexbox, RadioButton, Sizes } from '@sede-x/shell-ds-react-framework';
import { Address } from './types';

async function fetchCustomerAddres(
  customerId: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post(customerEndPoints.addressList, {
    customerId,
    pageNumber,
    pageSize
  });
}

const columnHelper = createColumnHelper<Address>();

const actions = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        {!!validateAddress(row.original).isValid && (
          <RadioButton
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            data-testid={`rowcheck-${row.index}`}
            size={Sizes.ExtraSmall}
          />
        )}
      </Flexbox>
    )
  })
];
const DEFAULT_PAGE_SIZE = 20;

const validateAddress = (address: Address) => {
  const result = {
    isValid: true,
    message: ''
  };

  if (!address.streetWithNumber) {
    result.isValid = false;
    result.message =
      'There is no street + number defined for selected address.';
  }

  if (!address.city) {
    result.isValid = false;
    result.message = 'There is no city defined for selected address.';
  }

  if (!address.zip) {
    result.isValid = false;
    result.message = 'There is no zip defined for selected address.';
  }

  if (!address.countryName) {
    result.isValid = false;
    result.message = 'There is no country defined for selected address.';
  }

  return result;
};

interface AddressesSelectionProps {
  customerId?: string;
}

const AddressesSelection: React.FC<AddressesSelectionProps> = ({
  customerId
}) => {
  const { setFieldValue } = useFormikContext<FormikValues>();
  const [selectedRows, setSelectedRows] = useState<Address[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { data, isLoading, isError } = useQuery({
    queryKey: ['t4e-customer-address', customerId],
    queryFn: () =>
      fetchCustomerAddres(customerId as string, pageNumber, pageSize).then(
        (res) => res.data
      ),
    refetchOnWindowFocus: 'always'
  });

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);

  useEffect(() => {
    if (selectedRows.length) {
      const row = selectedRows[0];
      setFieldValue('address', row);
    } else {
      setFieldValue('address', undefined);
    }
  }, [selectedRows]);

  return (
    <div
      className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
      data-testid="t4e-customer-addresses-selection-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
        <Table<Address>
          data={data?.data ?? []}
          columns={
            [
              ...actions,
              ...getOverviewColumns('T4EAddressesSelection')
            ] as ColumnDef<Address>[]
          }
          columnSelection={false}
          exportEnabled={false}
          enableMultiRowSelection={false}
          onSelectedRowsChange={setSelectedRows}
          enableRowSelection={(row) => validateAddress(row.original).isValid}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
          meta={{
            getRowStyles: (row) => ({
              backgroundColor: validateAddress(row.original).isValid
                ? ''
                : '#DFDFDF'
            })
          }}
          stickyColumns={['actions']}
          getRowId={(row) => row.addressID}
          getTooltip={(row) => validateAddress(row.original).message}
        />
      </QueryError>
    </div>
  );
};

export default AddressesSelection;
