import {
  Alignments,
  Prominences,
  Sizes,
  Text,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { StyledModal } from 'Pages/CustomerDetails/components/Actions/styles';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import React, { useRef, useState } from 'react';
import { queryClient } from 'react-query';
import { errorHelper } from 'utils/helpers/errorHelper';
import Summary from '../../Customer/T4ECustomerRegistration/components/Summary';
import VehicleList from '../components/VehicleList';
import { VehicleListDto } from '../components/types';

interface T4EUpdateVehicleProps {
  CustomerId: string;
  customerName: string;
  handleOnCloseModal: () => void;
}

const TOTAL_STEPS = 2;

const getTitles = (step: number) => {
  switch (step) {
    case 1:
      return 'Vehicle';
    case TOTAL_STEPS:
      return 'Summary';
    default:
      return '';
  }
};

const T4EUpdateVehicle: React.FC<T4EUpdateVehicleProps> = ({
  CustomerId,
  customerName,
  handleOnCloseModal
}) => {
  const { errorDialog, successDialog } = useConfirmDialogs();
  const [step, setStep] = useState<number>(1);
  const formRef = useRef<FormikProps<FormikValues>>(null);

  const renderTitle = () => (
    <>
      <div className="flex flex-col space-y-4 pl-2">
        <Text size={Sizes.Small}>T4E Vehicle Update Wizard</Text>
        <Text prominence={Prominences.Strong} bold size={Sizes.Medium}>
          {getTitles(step)}
        </Text>
      </div>

      <Text className="pl-2" size="small">
        {customerName}
      </Text>
    </>
  );

  const handleOnStepClick = (num: number, _isBackClick = false) => {
    setStep(num);
  };

  const handleClose = () => {
    handleOnCloseModal();
    setStep(1);
  };

  const handleOnClickBack = (stepNum: number) => {
    if (stepNum === 2) {
      formRef.current?.setFieldValue('vehicles', undefined);
    }
  };

  const nextDisabled = (values: FormikValues) => {
    if (step === 1) {
      return !values.vehicles;
    }
    return false;
  };

  const handleSave = (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    const { setSubmitting } = formikHelpers;

    const { vehicles } = values;

    const data = {
      customerId: CustomerId,
      vehicles: vehicles.map((vehicle: VehicleListDto) => ({
        vehicleId: vehicle.vehicleId,
        registrationNumber: vehicle.registrationNumber
      }))
    };

    customerInstance
      .post(t4eEndpoints.saveUpdateVehicleDetails, {
        ...data
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [t4eEndpoints.getRegisteredVehiclesWithoutObu]
        });
        successDialog('', 'Registration successfully inserted into processing');

        handleOnCloseModal();
        setStep(1);
      })
      .catch((error) => {
        const message = 'Failed to save the request.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      onSubmit={handleSave}
      innerRef={formRef}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <StyledModal
          title={renderTitle()}
          open
          width="90%"
          size={Sizes.Small}
          maskClosable={false}
          mask
          onClose={handleClose}
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          actions={[
            {
              label: 'CANCEL',
              action: () => {
                handleOnCloseModal();
              },
              props: {
                variant: Variants.Outlined
              }
            },
            {
              label: 'BACK',
              action: () => {
                handleOnClickBack(step);
                handleOnStepClick(step - 1, true);
              },
              props: {
                variant: Variants.Outlined,
                hidden: step === 1,
                style: {
                  display: step === 1 ? 'none' : ''
                }
              }
            },
            {
              label: 'NEXT',
              action: () => {
                handleOnStepClick(step + 1);
              },
              props: {
                disabled: nextDisabled(values),
                hidden: step === TOTAL_STEPS,
                style: {
                  display: step === TOTAL_STEPS ? 'none' : ''
                }
              }
            },
            {
              label: 'FINISH',
              action: () => {
                handleSubmit();
              },
              props: {
                disabled: isSubmitting,
                type: 'submit',
                hidden: step !== TOTAL_STEPS,
                style: {
                  display: step !== TOTAL_STEPS ? 'none' : ''
                },
                'aria-hidden': step !== TOTAL_STEPS
              }
            }
          ]}
        >
          {step === 1 && (
            <VehicleList
              customerId={CustomerId}
              endpoint={t4eEndpoints.getRegisteredVehiclesWithoutObu}
            />
          )}
          {step === TOTAL_STEPS && <Summary title="Vehicle Update Settings" />}
        </StyledModal>
      )}
    </Formik>
  );
};

export default T4EUpdateVehicle;
