import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { FormikValues, useFormikContext } from 'formik';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { t4eEndpoints } from 'api/apiEndpoints';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import OverviewColumns from 'components/Table/columns/overviewColumns.json';
import { Flexbox, SelectionCheckbox } from '@sede-x/shell-ds-react-framework';
import { columnBuilder } from 'utils/helpers';
import _ from 'lodash';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { ObuProductRef, ObuProductDto } from './types';

const DEFAULT_PAGE_SIZE = 20;

async function fetchObuProducts(
  customerId: string,
  endpoint: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post(endpoint, {
    customerId,
    pageNumber,
    pageSize
  });
}
interface OBUProductProps {
  customerId?: string;
  endpoint?: string;
}

const columnHelper = createColumnHelper<ObuProductDto>();

const actions = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        <SelectionCheckbox
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
          data-testid={`rowcheck-${row.index}`}
        />
      </Flexbox>
    ),
    header: ({ table }) => (
      <Flexbox gap="12px" alignItems="center">
        <SelectionCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      </Flexbox>
    )
  })
];

const OBUProduct = forwardRef<ObuProductRef, OBUProductProps>(
  ({ customerId, endpoint = t4eEndpoints.getActiveObus }, ref) => {
    const { setFieldValue } = useFormikContext<FormikValues>();
    const [selectedRows, setSelectedRows] = useState<ObuProductDto[]>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    const { infoDialog } = useConfirmDialogs();

    const { data, isLoading, isError } = useQuery({
      queryKey: [endpoint, customerId, pageSize, pageNumber],
      queryFn: () =>
        fetchObuProducts(
          customerId as string,
          endpoint,
          pageNumber,
          pageSize
        ).then((res) => res.data),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      cacheTime: 0
    });

    const handleChangePagination = (
      newPageNumber: number,
      newPageSize: number
    ) => {
      setPageNumber(newPageNumber);
      setPageSize(newPageSize);
    };

    const paginationProps = useSdsPagination(handleChangePagination);

    useEffect(() => {
      if (selectedRows.length) {
        setFieldValue('selectedObus', selectedRows);
      } else {
        setFieldValue('selectedObus', undefined);
      }
    }, [selectedRows]);

    useImperativeHandle(ref, () => ({
      async validateObus() {
        const VALIDATION_TITLE = 'Validation Error';
        if (isError || isLoading) {
          return false;
        }

        try {
          const response = await customerInstance.post(
            t4eEndpoints.validateObusPartnerVehicle,
            {
              obus: selectedRows.map((row) => ({
                obuId: row.id,
                obuSerialNo: row.obuSerialNumber
              }))
            }
          );

          const { success, messages } = response?.data || {};
          if (!success) {
            const message = messages || 'Failed to validate OBU';
            infoDialog(VALIDATION_TITLE, message);

            return false;
          }
        } catch (_error) {
          infoDialog(VALIDATION_TITLE, 'Failed to validate OBU');

          return false;
        }

        return true;
      }
    }));

    return (
      <div
        className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
        data-testid="obu-list"
      >
        <QueryError isLoading={isLoading} isError={isError}>
          <Table<ObuProductDto>
            data={data?.data ?? []}
            columns={
              [
                ...actions,
                ...columnBuilder(OverviewColumns.OBUProductDepersonalize)
              ] as ColumnDef<ObuProductDto>[]
            }
            columnSelection={false}
            exportEnabled={false}
            enableMultiRowSelection
            paginationData={{
              ...paginationProps,
              total: data?.count
            }}
            resetSelectedRowsOnPageChange={false}
            onSelectedRowsChange={(rows, rowSelectionState) => {
              setSelectedRows((prevSelectedRows) => {
                const allSelectedRows = [...prevSelectedRows, ...rows];
                const uniqueSelectedRows = _.uniqBy(allSelectedRows, 'id');
                return uniqueSelectedRows.filter(
                  (row) => rowSelectionState?.[row.id]
                );
              });
            }}
            getRowId={(row) => row.id}
          />
        </QueryError>
      </div>
    );
  }
);

export default OBUProduct;
