import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import QueryError from 'components/QueryError';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import Table from 'components/Table/Table';
import { FormikValues, useFormikContext } from 'formik';
import { useSdsPagination } from 'hooks/use-pagination';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { ColumnDef } from '@tanstack/react-table';
import { VehicleProductsDto } from '../../../Customer/T4ECustomerRegistration/components/types';
import { ObuProductDto } from './types';

async function fetchTollProducts(
  pageNumber: number,
  pageSize: number,
  endpoint: string,
  obus: ObuProductDto[]
) {
  return customerInstance.post(endpoint, {
    pageNumber,
    pageSize,
    obus: obus.map((obu) => ({
      obuId: obu.id,
      obuSerialNo: obu.obuSerialNumber
    }))
  });
}

interface ProductsProps {
  endpoint?: string;
  obus: ObuProductDto[];
}

const DEFAULT_PAGE_SIZE = 20;

const Products: React.FC<ProductsProps> = ({
  endpoint = t4eEndpoints.getTollProduct,
  obus
}) => {
  const { setFieldValue } = useFormikContext<FormikValues>();
  const [selectedRows, setSelectedRows] = useState<VehicleProductsDto[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { data, isLoading, isError } = useQuery({
    queryKey: [endpoint, pageNumber, pageSize, obus],
    queryFn: () =>
      fetchTollProducts(pageNumber, pageSize, endpoint, obus).then(
        (res) => res.data
      ),
    refetchOnWindowFocus: 'always',
    keepPreviousData: true,
    enabled: !!obus?.length,
    cacheTime: 0
  });

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);

  useEffect(() => {
    if (selectedRows.length) {
      setFieldValue('products', selectedRows);
    } else {
      setFieldValue('products', undefined);
    }
  }, [selectedRows]);

  return (
    <div
      className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
      data-testid="customer-t4e-product-selection-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
        <Table<VehicleProductsDto>
          data={data?.data ?? []}
          columns={
            getOverviewColumns(
              'T4EVehicleProduct'
            ) as ColumnDef<VehicleProductsDto>[]
          }
          columnSelection={false}
          exportEnabled={false}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
          stickyColumns={['actions']}
          getRowId={(row) => row.productTypeID}
          resetSelectedRowsOnPageChange={false}
          onSelectedRowsChange={(rows, rowSelectionState) => {
            setSelectedRows((prevSelectedRows) => {
              const allSelectedRows = [...prevSelectedRows, ...rows];
              const uniqueSelectedRows = _.uniqBy(
                allSelectedRows,
                'productTypeID'
              );
              return uniqueSelectedRows.filter(
                (row) => rowSelectionState?.[row.productTypeID]
              );
            });
          }}
        />
      </QueryError>
    </div>
  );
};

export default Products;
