import React from 'react';
import styled from 'styled-components';
import {
  ILoadingProps,
  IModalProps,
  Loading,
  Modal
} from '@sede-x/shell-ds-react-framework';

const StyledModal = styled(Modal)`
  & .ui-refresh-loading {
    width: auto !important;
  }
  & .ui-refresh-loading-content {
    min-height: auto !important;
    // background: transparent;
    // box-shadow: none;
  }

  & .ui-refresh-loading-body {
    width: 100%;
    background: transparent;
  }
`;

type ModalProps = Omit<IModalProps, 'open' | 'onClose'>;

interface LoginModalProps {
  modalProps?: ModalProps;
  loadingProps?: ILoadingProps;
}

const LoadingModal: React.FC<LoginModalProps> = ({
  modalProps,
  loadingProps
}) => (
  <StyledModal
    open
    onClose={() => {}}
    title=""
    centered
    closable={false}
    showHeader={false}
    maskClosable={false}
    bodyPadding={false}
    prefixCls="ui-refresh-loading"
    data-testid="loading-modal"
    {...modalProps}
  >
    <Loading {...loadingProps} />
  </StyledModal>
);

export default LoadingModal;
