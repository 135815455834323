import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import QueryError from 'components/QueryError';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import Table from 'components/Table/Table';
import { FormikValues, useFormikContext } from 'formik';
import { useSdsPagination } from 'hooks/use-pagination';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { TypeProductEnum } from 'utils/constants/constants';
import useEnums from 'hooks/useEnums';
import _ from 'lodash';
import { ColumnDef } from '@tanstack/react-table';
import { ProductsRef, VehicleProductsDto } from './types';
import { VehicleValidator } from '../../../utils/VehicleValidator';
import { constructErrors } from '../../../utils/helper';

async function fetchVehicleProducts(
  pageNumber: number,
  pageSize: number,
  endpoint: string
) {
  return customerInstance.post(endpoint, {
    pageNumber,
    pageSize
  });
}

interface ProductsProps {
  endpoint?: string;
  slovakiaToBeChecked?: boolean;
}

const DEFAULT_PAGE_SIZE = 20;

const Products = forwardRef<ProductsRef, ProductsProps>(
  (
    { endpoint = t4eEndpoints.vehicleProducts, slovakiaToBeChecked = false },
    ref
  ) => {
    const { setFieldValue, values } = useFormikContext<FormikValues>();
    const [selectedRows, setSelectedRows] = useState<VehicleProductsDto[]>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const { countryTypeEnum } = useEnums();
    const { infoDialog } = useConfirmDialogs();

    const { data, isLoading, isError } = useQuery({
      queryKey: [endpoint, pageNumber, pageSize],
      queryFn: () =>
        fetchVehicleProducts(pageNumber, pageSize, endpoint).then(
          (res) => res.data
        ),
      refetchOnWindowFocus: 'always',
      keepPreviousData: true
    });

    const handleChangePagination = (
      newPageNumber: number,
      newPageSize: number
    ) => {
      setPageNumber(newPageNumber);
      setPageSize(newPageSize);
    };

    const paginationProps = useSdsPagination(handleChangePagination);

    useEffect(() => {
      if (selectedRows.length) {
        const selectedRowsIds = selectedRows.map((row) => row.productTypeID);
        setFieldValue('vehicleProducts', selectedRows);
        setFieldValue('vehicleProductsIds', selectedRowsIds);
      } else {
        setFieldValue('vehicleProducts', undefined);
        setFieldValue('vehicleProductsIds', undefined);
      }
    }, [selectedRows]);

    useImperativeHandle(ref, () => ({
      validateVehicle() {
        const VALIDATION_TITLE = 'Validation Error';
        const error: string[] = [];

        const vehicles = values?.vehicles;

        if (isError) {
          return false;
        }
        if (!selectedRows.length) {
          infoDialog(
            VALIDATION_TITLE,
            'Select at least one vehicle first, then continue.'
          );
          return false;
        }
        if (
          values?.vehicleProductsIds?.includes(
            String(TypeProductEnum.Hungary.key).toLocaleLowerCase()
          )
        ) {
          error.push(...VehicleValidator.ValidateForHungary(vehicles));
        }

        // if switzerland is selected, check for switzerland validation
        if (
          values?.vehicleProductsIds?.includes(
            String(TypeProductEnum.Switzerland.key).toLocaleLowerCase()
          )
        ) {
          error.push(
            ...VehicleValidator.ValidateForSwitzerland(
              vehicles,
              countryTypeEnum
            )
          );
        }

        if (
          values?.vehicleProductsIds?.includes(
            String(TypeProductEnum.Austrian_Toll.key).toLocaleLowerCase()
          )
        ) {
          error.push(...VehicleValidator.ValidateForAustria(vehicles));
        }

        if (
          values?.vehicleProductsIds?.includes(
            String(TypeProductEnum.Toll_Germany.key).toLocaleLowerCase()
          )
        ) {
          error.push(...VehicleValidator.ValidateForGermany(vehicles));
        }

        if (
          values?.vehicleProductsIds?.includes(
            String(TypeProductEnum.Belgium.key).toLocaleLowerCase()
          )
        ) {
          error.push(...VehicleValidator.ValidateForBelgium(vehicles));
        }

        if (
          slovakiaToBeChecked &&
          values?.vehicleProductsIds?.includes(
            String(TypeProductEnum.Toll_Slovakia.key).toLocaleLowerCase()
          )
        ) {
          error.push(...VehicleValidator.ValidateForSlovakia(vehicles));
        }

        if (error.length) {
          infoDialog(VALIDATION_TITLE, constructErrors(error));
          return false;
        }

        return true;
      }
    }));

    return (
      <div
        className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
        data-testid="customer-t4e-product-selection-list"
      >
        <QueryError isLoading={isLoading} isError={isError}>
          <Table<VehicleProductsDto>
            data={data?.data ?? []}
            columns={
              getOverviewColumns(
                'T4EVehicleProduct'
              ) as ColumnDef<VehicleProductsDto>[]
            }
            columnSelection={false}
            exportEnabled={false}
            paginationData={{
              ...paginationProps,
              total: data?.count
            }}
            stickyColumns={['actions']}
            getRowId={(row) => row.productTypeID}
            resetSelectedRowsOnPageChange={false}
            onSelectedRowsChange={(rows, rowSelectionState) => {
              setSelectedRows((prevSelectedRows) => {
                const allSelectedRows = [...prevSelectedRows, ...rows];
                const uniqueSelectedRows = _.uniqBy(
                  allSelectedRows,
                  'productTypeID'
                );
                return uniqueSelectedRows.filter(
                  (row) => rowSelectionState?.[row.productTypeID]
                );
              });
            }}
          />
        </QueryError>
      </div>
    );
  }
);

export default Products;
