import React from 'react';
import { Menu, Icons } from '@sede-x/shell-ds-react-framework';
import { MenuInfo } from 'rc-menu/lib/interface';
import styled from 'styled-components';
import { useAuth } from 'auth/Authorization';
import { StyledAvatar } from './style';
import TelepassIcon from '../../assets/icons/telepass_icon.png';
import t4e_icon from '../../assets/icons/t4e_icon.png';

interface PopupMenuProps {
  onMenuClick: (menu: MenuInfo) => void;
}

const StyledMenu = styled(Menu)`
  .shell-menu-item {
    border-bottom: 1px solid rgba(28, 28, 28, 0.14);
    min-height: 56px;
    align-items: center;
  }
  &.shell-menu-submenu-popup {
    z-index: 3 !important;
  }

  .shell-menu-label-text {
    white-space: break-spaces !important;
  }
  .shell-menu-vertical {
    max-height: 60vh;
    overflow: auto;
  }
`;

const _telepassObuIems = {
  label: 'Telepass',
  key: 'obuTelepass',
  itemIcon: <StyledAvatar size="small" src={TelepassIcon} />,
  children: [
    {
      label: 'KMaster Registration - Deregistration',
      key: 'obuKmasterRegistrationDeregistration'
    },
    {
      label: 'OBU Toll service modification',
      key: 'obuTollServiceModification'
    },
    {
      label: 'OBU Replacement',
      key: 'obuReplacement'
    },
    {
      label: 'Update',
      key: 'update'
    },
    {
      label: 'OBU Registration',
      key: 'obuRegistration'
    }
  ]
};

const menuItems = [
  {
    label: 'Actions',
    key: 'actions',
    itemIcon: <Icons.Target />,
    children: [
      {
        label: 'Customer',
        key: 'customer',
        itemIcon: <Icons.PersonGear />,
        children: [
          {
            label: 'T4E',
            key: 'customerT4e',
            itemIcon: <StyledAvatar size="small" src={t4e_icon} />,
            children: [
              {
                label: 'Register Customer',
                key: 't4eRegisterCustomer'
              },
              {
                label: 'Complete Registration',
                key: 't4eCompleteCustomerRegistration'
              },
              {
                label: 'Update Customer',
                key: 't4eUpdateCustomer'
              },
              {
                label: 'Specials',
                key: 't4eSpecials',
                children: [
                  {
                    label: 'Request History',
                    key: 't4eRequestHistory'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        label: 'Vehicle',
        key: 'vehicle',
        itemIcon: <Icons.Truck />,
        children: [
          {
            label: 'T4E',
            key: 'vehicleT4e',
            itemIcon: <StyledAvatar size="small" src={t4e_icon} />,
            children: [
              {
                label: 'Register Vehicle And Configure Toll Product',
                key: 't4eRegisterVehicleAndConfigureTollProduct'
              },
              {
                label: 'Register Vehicle',
                key: 't4eRegisterVehicle'
              },
              {
                label: 'Update Vehicle',
                key: 't4eUpdateVehicle'
              },
              {
                label: 'Configure Toll Product',
                key: 't4eConfigureTollProduct'
              },
              {
                label: 'Get T4E Document',
                key: 't4eGetT4EDocument'
              },
              {
                label: 'Suspend Toll Product',
                key: 't4eSuspendTollProduct'
              },
              {
                label: 'Restore Toll Product',
                key: 't4eRestoreTollProduct'
              },
              {
                label: 'Terminate Toll Product',
                key: 't4eTerminateTollProduct'
              },
              {
                label: 'Pack Vehicle Documents',
                key: 't4ePackVehicleDocuments'
              }
            ]
          }
        ]
      },
      {
        label: 'OBU',
        key: 'obu',
        itemIcon: <Icons.Sensor />,
        children: [
          {
            label: 'T4E',
            key: 'obuT4e',
            itemIcon: <StyledAvatar size="small" src={t4e_icon} />,
            children: [
              {
                label: 'Order and personalize OBU',
                key: 'orderAndPersonalizeObu'
              },
              {
                label: 'Order OBU',
                key: 'orderObu'
              },
              {
                label: 'Transfer OBU',
                key: 'transferObu'
              },
              {
                label: 'Personalize OBU',
                key: 'personalizeObu'
              },
              {
                label: 'Depersonalize OBU',
                key: 'depersonalizeObu'
              },
              {
                label: 'Depersonalize OBU (eTM only)',
                key: 'depersonalizeObuEtmOnly'
              },
              {
                label: 'Complete Transfer OBU',
                key: 'completeTransferObu'
              },
              {
                label: 'Terminate OBU',
                key: 'terminateObu'
              }
            ]
          }
        ]
      }
    ]
  }
];

export const ActionMenu: React.FC<PopupMenuProps> = ({ onMenuClick }) => {
  const { verifyRoles, roles } = useAuth();
  return (
    <div>
      <StyledMenu
        items={menuItems}
        mode="vertical"
        onClick={onMenuClick}
        selectable={false}
        data-testid="action-menu"
        disabled={verifyRoles([roles.SOTR_VIEW])}
        triggerSubMenuAction="click"
      />
    </div>
  );
};
