import { Modal } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const ErrorModal = styled(Modal)`
  & .t4e-confirm-modal-content {
    min-height: auto !important;
  }

  & .t4e-confirm-modal-body {
    width: 100%;
  }
`;
