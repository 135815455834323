import React, { useEffect } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import { useQuery } from '@tanstack/react-query';
import OverviewColumns from 'components/Table/columns/overviewColumns.json';
import QueryError from 'components/QueryError';
import { BaseTable, Card, Sizes, Text } from '@sede-x/shell-ds-react-framework';
import { VehicleListDto } from '../../components/types';
import {
  TollDiscount,
  VehicleDetailsDto,
  VehicleProductsDto
} from '../../../Customer/T4ECustomerRegistration/components/types';

const vehicleColumns = [
  ...OverviewColumns.VehicleList.map((column) => ({
    header: column.label,
    accessorKey: column.field
  }))
];

interface SummaryProps {
  customerId: string;
}

const ValidateTollProduct = async (
  customerId: string,
  values: FormikValues
) => {
  const { vehicleProducts, selectedDiscounts, vehicles } = values;

  const payload = {
    customerId,
    selectedVehicles: vehicles.map(
      (vehicle: VehicleListDto) => vehicle.vehicleId
    ),
    selectedProducts: vehicleProducts?.map(
      ({ value, productTypeID }: VehicleProductsDto) => ({
        productTypeID,
        productCode: value
      })
    ),
    selectedDiscounts: selectedDiscounts?.map((discount: TollDiscount) => ({
      productTypeID: discount.productTypeID,
      name: discount.name
    }))
  };
  return customerInstance.post(
    t4eEndpoints.validateConfigureTollProduct,
    payload
  );
};

const Summary: React.FC<SummaryProps> = ({ customerId }) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const { vehicleProducts, vehicles } = values;

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      'validate-toll-product',
      customerId,
      ...vehicleProducts.map(
        (product: VehicleProductsDto) => product.productTypeID
      ),
      ...vehicles.map((vehicle: VehicleListDto) => vehicle.vehicleId)
    ],
    queryFn: () =>
      ValidateTollProduct(customerId, values).then((res) => res.data),
    enabled: !!customerId,
    retry: false
  });

  useEffect(() => {
    if (data) {
      const { isValidToProceedForSave: isAllowedToSave } = data?.data || {};
      setFieldValue('isValidToProceedForSave', isAllowedToSave);
    }
  }, [data]);

  const { isValidToProceedForSave } = values;
  const { selectedVehiclesToProceedForSave, selectedProductsToProceedForSave } =
    data?.data || {};

  return (
    <QueryError isError={isError} isLoading={isLoading}>
      <div className="flex flex-col grow overflow-y-auto p-5 bg-shellExtraPaleGrey2">
        <div className="flex">
          <Text>
            <Text bold>Toll Product Configuration Settings:</Text>
          </Text>
        </div>

        {isValidToProceedForSave ? (
          <RenderVehicleAndProduct
            vehiclesToShow={selectedVehiclesToProceedForSave}
            productsToShow={selectedProductsToProceedForSave?.map(
              (product: VehicleProductsDto) => product.productTypeID
            )}
          />
        ) : (
          <div className="flex flex-col gap-4 p-6 ">
            <Text>
              There is no change in product configuration of the selected
              vehicles.
            </Text>
          </div>
        )}
      </div>
    </QueryError>
  );
};

const RenderVehicleAndProduct = ({
  vehiclesToShow,
  productsToShow
}: {
  vehiclesToShow: string[];
  productsToShow: string[];
}) => {
  const { values } = useFormikContext<FormikValues>();

  const { vehicles, vehicleProducts, discountNames, vehicleDetails } =
    values || {};

  const isDocumentPresent = vehicleDetails?.some(
    (vehicleInfo: VehicleDetailsDto) =>
      vehicleInfo.documents?.some((document) => document.fileContent)
  );

  const showDocumentInfoDetails = isDocumentPresent;

  return (
    <>
      {vehicles && (
        <Card
          header={{ title: 'Selected Vehicles' }}
          size={Sizes.Small}
          bodyBgColor
        >
          <BaseTable
            columns={vehicleColumns}
            data={vehicles.filter((vehicle: VehicleListDto) =>
              vehiclesToShow?.includes(vehicle.vehicleId)
            )}
          />
        </Card>
      )}

      {showDocumentInfoDetails && (
        <Card
          header={{ title: 'Document Information' }}
          size={Sizes.Small}
          bodyBgColor
        >
          {vehicles?.map((vehicle: VehicleListDto) => {
            const vehicleDetail = (vehicleDetails as VehicleDetailsDto[]).find(
              (detail) => detail.vehicleId === vehicle.vehicleId
            );

            const obj = vehicleDetail?.documents?.map((document) => {
              const { documentTypeId, fileContent, fileName } = document;
              if (!fileContent) {
                return null;
              }
              return {
                fileName,
                documentTypeName:
                  documentTypeId.toLocaleLowerCase() ===
                  'c3a0fa3b-c4d9-4c59-83a0-44c8c87decd6'
                    ? 'Vehicle Registration documents'
                    : 'Vehicle Euro Certificate document'
              };
            });

            if (!obj) {
              return null;
            }

            return (
              <>
                <Text size={Sizes.Small} bold>
                  <u>{vehicle.registrationNumber}</u>
                </Text>
                {obj?.map((item) => {
                  if (!item) {
                    return null;
                  }
                  return (
                    <Text key={item.fileName} size={Sizes.Small}>
                      {item?.documentTypeName} - {item?.fileName}
                    </Text>
                  );
                })}
              </>
            );
          })}
        </Card>
      )}

      {vehicleProducts && (
        <Card
          header={{ title: 'Selected services' }}
          size={Sizes.Small}
          bodyBgColor
        >
          {vehicleProducts
            ?.filter((product: VehicleProductsDto) =>
              productsToShow?.includes(product.productTypeID)
            )
            .map((item: VehicleProductsDto) => (
              <Text key={item.value} size={Sizes.Small}>
                {item.name}
              </Text>
            ))}
        </Card>
      )}

      {discountNames && (
        <Card header={{ title: 'Discounts' }} size={Sizes.Small} bodyBgColor>
          {discountNames.map((item: string) => (
            <Text key={item} size={Sizes.Small}>
              {item}
            </Text>
          ))}
        </Card>
      )}
    </>
  );
};

export default Summary;
