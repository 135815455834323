import {
  Alignments,
  Prominences,
  Sizes,
  Text,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { t4eEndpoints } from 'api/apiEndpoints';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { StyledModal } from 'Pages/CustomerDetails/components/Actions/styles';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import React, { useRef, useState } from 'react';
import VehicleList from '../components/VehicleList';
import Summary from './Summary';

interface T4EPackVehicleDocumentsProps {
  CustomerId: string;
  customerName: string;
  handleOnCloseModal: () => void;
}

const TOTAL_STEPS = 2;

const getTitles = (step: number) => {
  switch (step) {
    case 1:
      return 'Vehicle';
    case TOTAL_STEPS:
      return 'Summary';
    default:
      return '';
  }
};

const T4EPackVehicleDocuments: React.FC<T4EPackVehicleDocumentsProps> = ({
  CustomerId,
  customerName,
  handleOnCloseModal
}) => {
  const { successDialog, errorDialog } = useConfirmDialogs();
  const [step, setStep] = useState<number>(1);
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const summaryRef = useRef<{ download: () => void }>(null);

  const renderTitle = () => (
    <>
      <div className="flex flex-col space-y-4 pl-2">
        <Text size={Sizes.Small}>T4E Pack Declaration Document wizard</Text>
        <Text prominence={Prominences.Strong} bold size={Sizes.Medium}>
          {getTitles(step)}
        </Text>
      </div>

      <Text className="pl-2" size="small">
        {customerName}
      </Text>
    </>
  );

  const handleOnStepClick = (num: number, _isBackClick = false) => {
    setStep(num);
  };

  const handleClose = () => {
    handleOnCloseModal();
    setStep(1);
  };

  const handleOnClickBack = (stepNum: number) => {
    if (stepNum === 2) {
      formRef.current?.setFieldValue('vehicles', undefined);
    }
  };

  const nextDisabled = (values: FormikValues) => {
    if (step === 1) {
      return !values.vehicles;
    }
    return false;
  };

  const handleSave = (
    _values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    const { setSubmitting } = formikHelpers;
    try {
      if (summaryRef.current) {
        summaryRef.current.download();
      }

      successDialog('', 'Package downloaded successfully');
      handleClose();
    } catch (error) {
      errorDialog('', 'Error downloading package');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      onSubmit={handleSave}
      innerRef={formRef}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <StyledModal
          title={renderTitle()}
          open
          width="90%"
          size={Sizes.Small}
          maskClosable={false}
          mask
          onClose={handleClose}
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          actions={[
            {
              label: 'CANCEL',
              action: () => {
                handleOnCloseModal();
              },
              props: {
                variant: Variants.Outlined
              }
            },
            {
              label: 'BACK',
              action: () => {
                handleOnClickBack(step);
                handleOnStepClick(step - 1, true);
              },
              props: {
                variant: Variants.Outlined,
                hidden: step === 1,
                style: {
                  display: step === 1 ? 'none' : ''
                }
              }
            },
            {
              label: 'NEXT',
              action: () => {
                handleOnStepClick(step + 1);
              },
              props: {
                disabled: nextDisabled(values),
                hidden: step === TOTAL_STEPS,
                style: {
                  display: step === TOTAL_STEPS ? 'none' : ''
                }
              }
            },
            {
              label: 'FINISH',
              action: () => {
                handleSubmit();
              },
              props: {
                disabled: isSubmitting || values.error,
                type: 'submit',
                hidden: step !== TOTAL_STEPS,
                style: {
                  display: step !== TOTAL_STEPS ? 'none' : ''
                },
                'aria-hidden': step !== TOTAL_STEPS
              }
            }
          ]}
        >
          {step === 1 && (
            <VehicleList
              customerId={CustomerId}
              endpoint={t4eEndpoints.getVehiclePackDeclaration}
            />
          )}
          {step === TOTAL_STEPS && <Summary ref={summaryRef} />}
        </StyledModal>
      )}
    </Formik>
  );
};

export default T4EPackVehicleDocuments;
