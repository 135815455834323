import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { FormikValues, useFormikContext } from 'formik';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import OverviewColumns from 'components/Table/columns/overviewColumns.json';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { Flexbox, RadioButton, Sizes } from '@sede-x/shell-ds-react-framework';
import { columnBuilder } from 'utils/helpers';
import { ObuDetailsDto, ObuRef } from './types';
import { ObuValidator } from '../../../utils/OBUValidator';
import {
  fetchCustomerOpenedRequestsObu,
  fetchCustomerPendingObus
} from '../../../utils/helper';

const DEFAULT_PAGE_SIZE = 20;

async function fetchCustomerObuPools(
  customerId: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post(
    'obu/actions/t4e/get-obu-complete-transfer-active-obus',
    {
      customerId,
      pageNumber,
      pageSize
    }
  );
}

interface OBUProductProps {
  customerId: string;
}

const columnHelper = createColumnHelper<ObuDetailsDto>();
const actions = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        {!!row.original.isEnabled && (
          <RadioButton
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            data-testid={`rowcheck-${row.index}`}
            size={Sizes.ExtraSmall}
          />
        )}
      </Flexbox>
    )
  })
];

const OBUProduct = forwardRef<ObuRef, OBUProductProps>(
  ({ customerId }, ref) => {
    const { setFieldValue } = useFormikContext<FormikValues>();
    const [selectedRows, setSelectedRows] = useState<ObuDetailsDto[]>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    const { infoDialog } = useConfirmDialogs();

    const { data, isLoading, isError } = useQuery({
      queryKey: [
        'get-obu-complete-transfer-active-obus',
        customerId,
        pageSize,
        pageNumber
      ],
      queryFn: () =>
        fetchCustomerObuPools(customerId, pageNumber, pageSize).then(
          (res) => res.data
        ),
      refetchOnWindowFocus: 'always',
      keepPreviousData: true
    });

    const { data: pendingObusData, isLoading: pendingObusIsLoading } = useQuery(
      {
        queryKey: ['get-pending-obu', customerId],
        queryFn: () =>
          fetchCustomerPendingObus(customerId).then((res) => res.data),
        refetchOnWindowFocus: 'always',
        retry: false
      }
    );

    const {
      data: openedRequestsObuData,
      isLoading: openedRequestsObuIsLoading
    } = useQuery({
      queryKey: ['get-opened-requests-obu', customerId],
      queryFn: () =>
        fetchCustomerOpenedRequestsObu(customerId).then((res) => res.data),
      refetchOnWindowFocus: 'always',
      retry: false
    });

    const handleChangePagination = (
      newPageNumber: number,
      newPageSize: number
    ) => {
      setPageNumber(newPageNumber);
      setPageSize(newPageSize);
    };

    const paginationProps = useSdsPagination(handleChangePagination);

    useEffect(() => {
      if (selectedRows.length) {
        const row = selectedRows[0];
        setFieldValue('customerId', customerId);
        setFieldValue('obuSerialNumber', row.obuSerialNumber);
        setFieldValue('obuProductType', row.obuProductType);
        setFieldValue('obuId', row.id);
      } else {
        setFieldValue('obuId', undefined);
        setFieldValue('obuSerialNumber', undefined);
        setFieldValue('obuProductType', undefined);
      }
    }, [selectedRows]);

    useImperativeHandle(ref, () => ({
      validateObu() {
        const VALIDATION_TITLE = 'Validation Error';
        if (isError) {
          return false;
        }

        const selectedObu = selectedRows[0];
        const pendingObus = pendingObusData?.data;
        const openedRequestsObus = openedRequestsObuData?.data;

        if (selectedObu && pendingObus) {
          let error = ObuValidator.ValidateForFormatting(
            selectedObu,
            pendingObus
          );

          if (error) {
            infoDialog(VALIDATION_TITLE, error);
            return false;
          }

          error = ObuValidator.ValidateSerialNumber(selectedObu);
          if (error) {
            infoDialog(VALIDATION_TITLE, error);
            return false;
          }

          error = ObuValidator.ValidateForOpenedRequests(
            selectedObu,
            openedRequestsObus
          );

          if (error) {
            infoDialog(VALIDATION_TITLE, error);
            return false;
          }
        } else {
          infoDialog(VALIDATION_TITLE, 'OBU cannot be validated.');
          return false;
        }

        return true;
      }
    }));

    return (
      <div
        className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
        data-testid="obu-list"
      >
        <QueryError
          isLoading={
            isLoading || pendingObusIsLoading || openedRequestsObuIsLoading
          }
          isError={isError}
        >
          <Table<ObuDetailsDto>
            data={data?.data ?? []}
            columns={
              [
                ...actions,
                ...columnBuilder(OverviewColumns.OBUProductDepersonalize)
              ] as ColumnDef<ObuDetailsDto>[]
            }
            columnSelection={false}
            exportEnabled={false}
            enableMultiRowSelection={false}
            onSelectedRowsChange={setSelectedRows}
            resetSelectedRowsOnPageChange={false}
            paginationData={{
              ...paginationProps,
              total: data?.count
            }}
            getRowId={(row) => row.id}
            meta={{
              getRowStyles: (row) => ({
                backgroundColor: row.original.isEnabled ? '' : '#DFDFDF'
              })
            }}
            getTooltip={(row) => row.original.validationMessage}
          />
        </QueryError>
      </div>
    );
  }
);

export default OBUProduct;
