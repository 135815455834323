import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { useFormikContext, FormikValues } from 'formik';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import { ColumnDef } from '@tanstack/react-table';
import { useState, useEffect } from 'react';
import { customerEndPoints } from 'api/apiEndpoints';
import { CardsDto } from './types';

async function fetchCustomerAddres(
  customerId: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post(customerEndPoints.cardList, {
    customerId,
    pageNumber,
    pageSize
  });
}

const DEFAULT_PAGE_SIZE = 20;
interface AddressesSelectionProps {
  customerId?: string;
}

const CardSelection: React.FC<AddressesSelectionProps> = ({ customerId }) => {
  const { setFieldValue } = useFormikContext<FormikValues>();
  const [selectedRows, setSelectedRows] = useState<CardsDto[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { data, isLoading, isError } = useQuery({
    queryKey: ['t4e-customer-cards', customerId, pageNumber, pageSize],
    queryFn: () =>
      fetchCustomerAddres(customerId as string, pageNumber, pageSize).then(
        (res) => res.data
      ),
    refetchOnWindowFocus: 'always',
    keepPreviousData: true
  });

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);

  useEffect(() => {
    if (selectedRows.length) {
      const row = selectedRows[0];
      setFieldValue('card', row);
    } else {
      setFieldValue('card', undefined);
    }
  }, [selectedRows]);

  return (
    <div
      className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
      data-testid="t4e-customer-card-selection-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
        <Table<CardsDto>
          data={data?.data ?? []}
          columns={
            getOverviewColumns('T4ECardSelection') as ColumnDef<CardsDto>[]
          }
          columnSelection={false}
          exportEnabled={false}
          enableMultiRowSelection={false}
          onSelectedRowsChange={setSelectedRows}
          resetSelectedRowsOnPageChange={false}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
          stickyColumns={['actions']}
          getRowId={(row) => row.cardID}
        />
      </QueryError>
    </div>
  );
};

export default CardSelection;
