import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';

interface ValidationResult {
  success: boolean;
  message: string;
  error: boolean;
  loading?: boolean;
  data?: boolean;
}

async function fetchCustomerValidation(customerId: string, endpoint: string) {
  return customerInstance.post(endpoint, {
    customerId
  });
}

const useValidateT4E = (
  customerId: string,
  isEnabled: boolean = true,
  endpoint: string = t4eEndpoints.validate
): ValidationResult => {
  const { data, isError, isLoading, isFetching } = useQuery({
    queryKey: ['customer-t4e-validation', customerId, isEnabled],
    queryFn: () =>
      fetchCustomerValidation(customerId, endpoint).then((res) => res.data),
    refetchOnWindowFocus: false,
    enabled: isEnabled
  });

  return {
    success: data?.success,
    message: data?.messages,
    error: isError,
    loading: isLoading || isFetching,
    data: data?.data
  };
};

export default useValidateT4E;
