import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import QueryError from 'components/QueryError';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import React, { useState } from 'react';

async function fetchParcelDetails(
  requestHistoryId: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post(t4eEndpoints.parcelDetails, {
    requestHistoryId,
    pageNumber,
    pageSize
  });
}

const DEFAULT_PAGE_SIZE = 20;

interface ParcelDetailsProps {
  requestHistoryId: string;
}

const ParcelDetails: React.FC<ParcelDetailsProps> = ({ requestHistoryId }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const { data, isLoading, isError } = useQuery({
    queryKey: ['t4e-parcel-Details', requestHistoryId, pageNumber, pageSize],
    queryFn: () =>
      fetchParcelDetails(requestHistoryId, pageNumber, pageSize).then(
        (res) => res.data
      ),
    refetchOnWindowFocus: false,
    enabled: !!requestHistoryId
  });

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);
  return (
    <div
      className="flex flex-col grow overflow-y-auto gap-2 p-5 bg-shellExtraPaleGrey2 h-full "
      data-testid="parcel-details-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
        <Table
          data={data?.data ?? []}
          columns={getOverviewColumns('T4EOBURequestHistoryParcelDetails')}
          columnSelection={false}
          exportEnabled={false}
          enableRowSelection={false}
          enableMultiRowSelection={false}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
        />
      </QueryError>
    </div>
  );
};

export default ParcelDetails;
