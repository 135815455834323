import {
  Alert,
  Alignments,
  Prominences,
  Sentiments,
  Sizes,
  Text,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { StyledModal } from 'Pages/CustomerDetails/components/Actions/styles';
import React, { useRef, useState } from 'react';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import { errorHelper } from 'utils/helpers/errorHelper';
import { queryClient } from 'react-query';
import useValidateT4E from 'hooks/useValidateT4E';
import LoadingModal from 'components/LoadingModal/LoadingModal';
import CustomerDetails from './components/CustomerDetails';
import AddressesSelection from './components/AddressesSelection';
import { CustomerRef } from './components/types';
import Summary from './components/Summary';
import { ErrorModal } from './styles';

interface TT4EUpdateCustomerProps {
  CustomerId: string;
  customerName: string;
  handleOnCloseModal: () => void;
}

const TOTAL_STEPS = 3;
const STEP_3 = 3;

const getTitles = (step: number) => {
  switch (step) {
    case 1:
      return 'Customer Details';
    case 2:
      return 'Business Address';
    case TOTAL_STEPS:
      return 'Summary';
    default:
      return '';
  }
};

const T4EUpdateCustomer: React.FC<TT4EUpdateCustomerProps> = ({
  CustomerId,
  customerName,
  handleOnCloseModal
}) => {
  const { errorDialog, successDialog } = useConfirmDialogs();
  const [step, setStep] = useState<number>(1);
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const { message, error, success, loading } = useValidateT4E(
    CustomerId,
    true,
    t4eEndpoints.validateCustomerUpdate
  );

  const customerDetailsRef = useRef<CustomerRef>(null);

  const renderTitle = () => (
    <>
      <div className="flex flex-col space-y-4 pl-2">
        <Text size={Sizes.Small}>T4E Customer Update</Text>
        <Text prominence={Prominences.Strong} bold size={Sizes.Medium}>
          {getTitles(step)}
        </Text>
      </div>

      <Text className="pl-2" size="small">
        {customerName}
      </Text>
    </>
  );

  const handleOnStepClick = (num: number, isBackClick = false) => {
    if (
      step === 1 &&
      !isBackClick &&
      !customerDetailsRef.current?.validateCustomer()
    ) {
      return;
    }
    setStep(num);
  };

  const handleClose = () => {
    handleOnCloseModal();
    setStep(1);
  };

  const handleOnClickBack = (stepNum: number) => {
    switch (stepNum) {
      case 2:
        formRef.current?.setFieldValue('error', undefined);

        formRef.current?.setFieldValue('address', undefined);
        break;
      case STEP_3:
        formRef.current?.setFieldValue('address', undefined);
        break;
      default:
        break;
    }
  };

  const nextDisabled = (values: FormikValues) => {
    switch (step) {
      case 1:
        return values.error;
      case 2:
        return !values.address;

      default:
        return false;
    }
  };

  const handleSave = (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    const { setSubmitting } = formikHelpers;

    const {
      address: { addressID }
    } = values;

    const data = {
      customerId: CustomerId,
      addressId: addressID
    };

    customerInstance
      .post(t4eEndpoints.saveCustomerUpdate, {
        ...data
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['customercontracts-list', CustomerId]
        });
        queryClient.invalidateQueries({
          queryKey: ['customer-t4e-validation', CustomerId]
        });
        successDialog('', 'Request has been successfully saved');

        handleOnCloseModal();
      })
      .catch((err) => {
        const defaultErrorMessage = 'Failed to save the request.';
        errorDialog('Error', errorHelper(err, defaultErrorMessage));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (loading) {
    return (
      <LoadingModal
        modalProps={{
          mask: false
        }}
      />
    );
  }

  if (error) {
    return (
      <ErrorModal
        title=" "
        onClose={handleClose}
        open
        showHeader={false}
        closable={false}
        maskClosable={false}
        bodyPadding={false}
        centered
        prefixCls="t4e-confirm-modal"
      >
        <Alert
          sentiment={Sentiments.Warning}
          dismissible
          onDismissClick={handleClose}
        >
          <div className="max-h-56 overflow-auto">
            Failed to validate the customer
          </div>
        </Alert>
      </ErrorModal>
    );
  }

  if (!success) {
    return (
      <ErrorModal
        title=" "
        onClose={handleClose}
        open
        showHeader={false}
        closable={false}
        maskClosable={false}
        bodyPadding={false}
        centered
        prefixCls="t4e-confirm-modal"
      >
        <Alert
          sentiment={Sentiments.Warning}
          dismissible
          onDismissClick={handleClose}
        >
          <div className="max-h-56 overflow-auto">{message}</div>
        </Alert>
      </ErrorModal>
    );
  }
  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      onSubmit={handleSave}
      innerRef={formRef}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <StyledModal
          title={renderTitle()}
          open
          width="90%"
          size={Sizes.Small}
          maskClosable={false}
          mask
          onClose={handleClose}
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          actions={[
            {
              label: 'CANCEL',
              action: () => {
                handleOnCloseModal();
              },
              props: {
                variant: Variants.Outlined
              }
            },
            {
              label: 'BACK',
              action: () => {
                handleOnClickBack(step);
                handleOnStepClick(step - 1, true);
              },
              props: {
                variant: Variants.Outlined,
                hidden: step === 1,
                style: {
                  display: step === 1 ? 'none' : ''
                }
              }
            },
            {
              label: 'NEXT',
              action: () => {
                handleOnStepClick(step + 1);
              },
              props: {
                disabled: nextDisabled(values),
                hidden: step === TOTAL_STEPS,
                style: {
                  display: step === TOTAL_STEPS ? 'none' : ''
                }
              }
            },
            {
              label: 'FINISH',
              action: () => {
                handleSubmit();
              },
              props: {
                disabled: isSubmitting,
                type: 'submit',
                hidden: step !== TOTAL_STEPS,
                style: {
                  display: step !== TOTAL_STEPS ? 'none' : ''
                },
                'aria-hidden': step !== TOTAL_STEPS
              }
            }
          ]}
        >
          {step === 1 && (
            <CustomerDetails
              customerId={CustomerId}
              ref={customerDetailsRef}
              checkCustomer={false}
            />
          )}
          {step === 2 && <AddressesSelection customerId={CustomerId} />}
          {step === TOTAL_STEPS && (
            <Summary title="Customer contract update settings" />
          )}
        </StyledModal>
      )}
    </Formik>
  );
};

export default T4EUpdateCustomer;
