import {
  Alignments,
  Prominences,
  Sizes,
  Text,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { StyledModal } from 'Pages/CustomerDetails/components/Actions/styles';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import React, { useRef, useState } from 'react';
import { queryClient } from 'react-query';
import { errorHelper } from 'utils/helpers/errorHelper';
import { TypeProductEnum } from 'utils/constants/constants';
import { createMap } from 'utils/helpers';
import Products from '../../Customer/T4ECustomerRegistration/components/Products';
import {
  DocumentRef,
  ProductsRef,
  TollDiscount,
  VehicleDetailsDto,
  VehicleProductsDto
} from '../../Customer/T4ECustomerRegistration/components/types';
import TollDiscounts from '../../Customer/T4ECustomerRegistration/components/TollDiscounts';
import DocumentSelection from '../../Customer/T4ECustomerRegistration/components/DocumentSelection';
import VehicleList from '../components/VehicleList';
import Summary from './components/Summary';
import { VehicleMap, VehicleRef } from '../components/types';

interface T4EConfigureTollProductProps {
  CustomerId: string;
  customerName: string;
  handleOnCloseModal: () => void;
}

const TOTAL_STEPS = 5;
const STEP_3 = 3;
const STEP_4 = 4;

const getTitles = (step: number) => {
  switch (step) {
    case 1:
      return 'Vehicle';
    case 2:
      return 'Products';
    case STEP_3:
      return 'Toll Discounts';
    case STEP_4:
      return 'Upload Vehicle Documents';
    case TOTAL_STEPS:
      return 'Summary';
    default:
      return '';
  }
};

const T4EConfigureTollProduct: React.FC<T4EConfigureTollProductProps> = ({
  CustomerId,
  customerName,
  handleOnCloseModal
}) => {
  const { errorDialog, successDialog } = useConfirmDialogs();
  const [step, setStep] = useState<number>(1);
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const productsRef = useRef<ProductsRef>(null);
  const documentRef = useRef<DocumentRef>(null);
  const vehicleDetailRef = useRef<VehicleRef>(null);

  const renderTitle = () => (
    <>
      <div className="flex flex-col space-y-4 pl-2">
        <Text size={Sizes.Small}>T4E Configure Toll Product Wizard</Text>
        <Text prominence={Prominences.Strong} bold size={Sizes.Medium}>
          {getTitles(step)}
        </Text>
      </div>

      <Text className="pl-2" size="small">
        {customerName}
      </Text>
    </>
  );

  const handleOnStepClick = async (num: number, isBackClick = false) => {
    if (
      step === 1 &&
      !isBackClick &&
      !(await vehicleDetailRef.current?.validateVehicle())
    ) {
      return;
    }
    if (step === 2 && !isBackClick && !productsRef.current?.validateVehicle()) {
      return;
    }

    if (
      step === STEP_4 &&
      !isBackClick &&
      !(await documentRef.current?.validateDocument())
    ) {
      return;
    }
    handleStepNavigation(num, isBackClick);
  };

  const handleStepNavigation = (num: number, isBackClick: boolean) => {
    if (step === 2 && !isBackClick) {
      handleStep2Navigation();
      return;
    }

    if (step === STEP_3 && !isBackClick) {
      handleStep3Navigation();
      return;
    }

    if (step === STEP_4 && isBackClick) {
      handleStep4BackNavigation();
      return;
    }

    if (step === TOTAL_STEPS && isBackClick) {
      handleTotalStepsBackNavigation();
      return;
    }

    setStep(num);
  };

  const handleTotalStepsBackNavigation = () => {
    const { vehicleDetails, discountNames } = formRef.current?.values || {};

    if (vehicleDetails?.some((v: VehicleDetailsDto) => v.documents)) {
      setStep(STEP_4);
    } else if (discountNames?.length) {
      setStep(STEP_3);
    } else {
      setStep(2);
    }
  };

  const handleStep2Navigation = () => {
    const { vehicleProductsIds } = formRef.current?.values || {};
    const lowerCaseIds = vehicleProductsIds?.map((id: string) =>
      id.toLocaleLowerCase()
    );

    const includesProduct = (productKey: string) =>
      lowerCaseIds?.includes(String(productKey).toLocaleLowerCase());

    if (includesProduct(TypeProductEnum.Toll_France.key as string)) {
      setStep(STEP_3);
    } else if (
      includesProduct(TypeProductEnum.Hungary.key as string) ||
      includesProduct(TypeProductEnum.Bulgaria.key as string)
    ) {
      setStep(STEP_4);
    } else {
      setStep(TOTAL_STEPS);
    }
  };

  const handleStep3Navigation = () => {
    const { vehicleProductsIds } = formRef.current?.values || {};
    const lowerCaseIds = vehicleProductsIds?.map((id: string) =>
      id.toLocaleLowerCase()
    );

    const includesProduct = (productKey: string) =>
      lowerCaseIds?.includes(String(productKey).toLocaleLowerCase());

    if (
      includesProduct(TypeProductEnum.Hungary.key as string) ||
      includesProduct(TypeProductEnum.Bulgaria.key as string)
    ) {
      setStep(STEP_4);
    } else {
      setStep(TOTAL_STEPS);
    }
  };

  const handleStep4BackNavigation = () => {
    const { vehicleProductsIds } = formRef.current?.values || {};
    const lowerCaseIds = vehicleProductsIds?.map((id: string) =>
      id.toLocaleLowerCase()
    );

    const includesProduct = (productKey: string) =>
      lowerCaseIds?.includes(String(productKey).toLocaleLowerCase());

    if (includesProduct(TypeProductEnum.Toll_France.key as string)) {
      setStep(STEP_3);
    } else {
      setStep(2);
    }
  };

  const handleClose = () => {
    handleOnCloseModal();
    setStep(1);
  };

  const handleOnClickBack = (stepNum: number) => {
    switch (stepNum) {
      case 2:
        formRef.current?.setFieldValue('vehicleProducts', undefined);
        formRef.current?.setFieldValue('vehicleProductsIds', undefined);
        formRef.current?.setFieldValue('vehicles', undefined);
        formRef.current?.setFieldValue('vehicleDetails', undefined);
        break;
      case STEP_3:
        formRef.current?.setFieldValue('vehicleProducts', undefined);
        formRef.current?.setFieldValue('vehicleProductsIds', undefined);
        formRef.current?.setFieldValue('discountNames', undefined);
        formRef.current?.setFieldValue('selectedDiscounts', undefined);
        break;

      case STEP_4: {
        const vehicleDetails = formRef.current?.values.vehicleDetails;
        if (vehicleDetails) {
          const updatedVehicleDetails = vehicleDetails.map(
            (vehicle: VehicleDetailsDto) => {
              const { documents, ...rest } = vehicle;
              return rest;
            }
          );
          formRef.current?.setFieldValue(
            'vehicleDetails',
            updatedVehicleDetails
          );
        }
        break;
      }
      default:
        break;
    }
  };

  const nextDisabled = (values: FormikValues) => {
    switch (step) {
      case 1:
        return !values.vehicles;
      case 2:
        return !values.vehicleProducts && !values.vehicleProductsIds;

      case STEP_4:
        return values?.documentLoading;
      default:
        return false;
    }
  };

  const handleSave = (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    const { setSubmitting } = formikHelpers;

    const { vehicleDetails, vehicleProducts, selectedDiscounts, vehicles } =
      values;

    const vehicleMap = createMap(
      vehicles,
      'vehicleId'
    ) as unknown as VehicleMap;

    const payload = {
      customerId: CustomerId,
      selectedVehiclesWithVehicleDocuments: vehicleDetails.map(
        (details: VehicleDetailsDto) => ({
          ...details,
          registrationNumber: vehicleMap[details.vehicleId].registrationNumber
        })
      ),
      selectedProducts: vehicleProducts?.map(
        ({ value, productTypeID }: VehicleProductsDto) => ({
          productTypeID,
          productCode: value
        })
      ),
      selectedDiscounts: selectedDiscounts?.map((discount: TollDiscount) => ({
        productTypeID: discount.productTypeID,
        name: discount.name
      }))
    };

    customerInstance
      .post(t4eEndpoints.saveConfigureTollProduct, {
        ...payload
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [t4eEndpoints.getRegisteredVehiclesWithoutObu, CustomerId]
        });
        successDialog(
          '',
          'Configuration successfully inserted into processing'
        );

        handleOnCloseModal();
        setStep(1);
      })
      .catch((error) => {
        const message = 'Failed to save the request.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      onSubmit={handleSave}
      innerRef={formRef}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <StyledModal
          title={renderTitle()}
          open
          width="90%"
          size={Sizes.Small}
          maskClosable={false}
          mask
          onClose={handleClose}
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          actions={[
            {
              label: 'CANCEL',
              action: () => {
                handleOnCloseModal();
              },
              props: {
                variant: Variants.Outlined
              }
            },
            {
              label: 'BACK',
              action: () => {
                handleOnClickBack(step);
                handleOnStepClick(step - 1, true);
              },
              props: {
                variant: Variants.Outlined,
                hidden: step === 1,
                style: {
                  display: step === 1 ? 'none' : ''
                }
              }
            },
            {
              label: 'NEXT',
              action: () => {
                handleOnStepClick(step + 1);
              },
              props: {
                disabled: nextDisabled(values),
                hidden: step === TOTAL_STEPS,
                style: {
                  display: step === TOTAL_STEPS ? 'none' : ''
                }
              }
            },
            {
              label: 'FINISH',
              action: () => {
                handleSubmit();
              },
              props: {
                disabled: isSubmitting || !values?.isValidToProceedForSave,
                type: 'submit',
                hidden: step !== TOTAL_STEPS,
                style: {
                  display: step !== TOTAL_STEPS ? 'none' : ''
                },
                'aria-hidden': step !== TOTAL_STEPS
              }
            }
          ]}
        >
          {step === 1 && (
            <VehicleList
              customerId={CustomerId}
              endpoint={t4eEndpoints.getRegisteredVehiclesWithoutObu}
              showProductStatus
              ref={vehicleDetailRef}
              validatePartnerDetails
            />
          )}
          {step === 2 && (
            <Products
              ref={productsRef}
              endpoint={t4eEndpoints.getVehicleConfigureProduct}
              slovakiaToBeChecked
            />
          )}
          {step === STEP_3 && <TollDiscounts />}
          {step === STEP_4 && (
            <DocumentSelection ref={documentRef} showConfirmationOnError />
          )}
          {step === TOTAL_STEPS && <Summary customerId={CustomerId} />}
        </StyledModal>
      )}
    </Formik>
  );
};

export default T4EConfigureTollProduct;
