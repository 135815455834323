import {
  Alignments,
  Button,
  Icons,
  Modal,
  Sizes,
  Tooltip
} from '@sede-x/shell-ds-react-framework';
import React, { useRef, useState } from 'react';
import Tabs from 'components/Tabs/Tabs';
import styled from 'styled-components';
import RequestHistory from './components/RequestHistory';
import { RequestHistoryRef } from './components/types';

export const StyledModal = styled(Modal)`
  .shell-modal-container-content {
    height: 80vh;
  }
`;

interface T4ERequestHistoryProps {
  CustomerId: string;
  handleOnCloseModal: () => void;
}

const T4ERequestHistory: React.FC<T4ERequestHistoryProps> = ({
  CustomerId,
  handleOnCloseModal
}) => {
  const [selectedTab, setSelectedTab] = useState<string>('customerRequests');
  const requestRefCustomer = useRef<RequestHistoryRef>(null);
  const requestRefVehicle = useRef<RequestHistoryRef>(null);
  const requestRefObu = useRef<RequestHistoryRef>(null);

  const handleClose = () => {
    handleOnCloseModal();
  };

  const items = [
    {
      label: 'Customer Requests',
      key: 'customerRequests',
      children: (
        <RequestHistory customerId={CustomerId} ref={requestRefCustomer} />
      )
    },
    {
      label: 'Vehicle Requests',
      key: 'vehicleRequests',
      children: (
        <RequestHistory
          customerId={CustomerId}
          type="vehicle"
          ref={requestRefVehicle}
        />
      )
    },
    {
      label: 'OBU Requests',
      key: 'obuRequests',
      children: (
        <RequestHistory
          customerId={CustomerId}
          type="obu"
          ref={requestRefObu}
        />
      )
    }
  ];

  return (
    <StyledModal
      title="T4E Request History"
      open
      width="95%"
      size={Sizes.Small}
      maskClosable={false}
      mask
      onClose={handleClose}
      contentScrollable
      actionsAlignment={Alignments.Right}
      centered
      bodyPadding={false}
    >
      <Tabs
        items={items}
        onChange={(key) => {
          setSelectedTab(key);
        }}
        className="p-2"
        sideElements={
          <div className="p-5">
            <Tooltip
              overlay="Refresh"
              trigger={
                <Button
                  onClick={() => {
                    switch (selectedTab) {
                      case 'customerRequests':
                        requestRefCustomer.current?.refetchData();
                        break;
                      case 'vehicleRequests':
                        requestRefVehicle.current?.refetchData();
                        break;
                      case 'obuRequests':
                        requestRefObu.current?.refetchData();
                        break;
                      default:
                        break;
                    }
                  }}
                  size={Sizes.Small}
                  icon={<Icons.ArrowCwCircle width={15} height={15} />}
                  iconOnly
                  data-testid="refresh-button"
                >
                  Refresh
                </Button>
              }
            />
          </div>
        }
      />
    </StyledModal>
  );
};

export default T4ERequestHistory;
