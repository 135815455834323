import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { Field, useFormikContext } from 'formik';
import Table from 'components/Table/Table';
import { useState, useEffect, useMemo, ChangeEvent } from 'react';
import { customerEndPoints } from 'api/apiEndpoints';
import {
  Column,
  ColumnDef,
  createColumnHelper,
  Row
} from '@tanstack/react-table';
import { STALE_TIME } from 'react-query';
import NumberField from 'components/FormCmponents/NumberField';
import { DepersonalizeEtmOnlyDto, ContractDetailDto } from './types';

interface ContractDetailsProps {
  customerId?: string;
  ObuId?: string;
}
const columnHelper = createColumnHelper<ContractDetailDto>();

const defaultColoumns = [
  {
    header: 'Product',
    id: 'productName',
    accessorKey: 'productName'
  },
  {
    header: 'Old contract Id',
    id: 'oldContractId',
    accessorKey: 'oldContractId'
  }
];
interface RenderColumnProps {
  row: Row<ContractDetailDto>;
  column: Column<ContractDetailDto>;
  onChange: (rowIndex: number, columnId: string, value: string) => void;
}

const RenderColumn: React.FC<RenderColumnProps> = ({
  row,
  column,
  onChange
}) => (
  <Field
    id="newContractId"
    name="newContractId"
    placeholder="Type here"
    component={NumberField}
    value={row.original.newContractId}
    onChange={(event: ChangeEvent<HTMLInputElement>) => {
      onChange(row.index, column.id, event.target.value);
    }}
  />
);

async function fetchContractDetails(customerId: string, ObuId: string) {
  return customerInstance.post(customerEndPoints.getContactListDepersonalize, {
    customerId,
    ObuId
  });
}

const ContractDetails: React.FC<ContractDetailsProps> = ({
  customerId,
  ObuId
}) => {
  const { setFieldValue } = useFormikContext<DepersonalizeEtmOnlyDto>();

  const [services, setServices] = useState<ContractDetailDto[]>([]);

  const { data, isLoading, isError } = useQuery({
    queryKey: ['get-obu-toll-service-contract-data', customerId],
    queryFn: () =>
      fetchContractDetails(customerId as string, ObuId as string).then(
        (res) => res.data
      ),
    staleTime: STALE_TIME,
    refetchOnWindowFocus: 'always',
    enabled: !!ObuId
  });

  useEffect(() => {
    if (data?.data) {
      setServices(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (services) {
      setFieldValue('contractChangeData', services);
    }
  }, [services]);

  const onChange = (rowIndex: number, columnId: string, value: unknown) => {
    setServices((prev) =>
      prev.map((row, index) =>
        index === rowIndex ? { ...row, [columnId]: value } : row
      )
    );
  };

  const columns = useMemo<ColumnDef<ContractDetailDto>[]>(
    () => [
      ...defaultColoumns,
      columnHelper.display({
        id: 'newContractId',
        header: 'New Contract Id',
        size: 20,
        cell: ({ row, column }) => RenderColumn({ row, column, onChange })
      })
    ],
    [data]
  );

  return (
    <div
      className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
      data-testid="obu-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
        <div className="text-sm text-shellRed pl-1">
          * Please add new contract ID for all the product which can be found in
          the T4E TAPE Vehicle tab.
        </div>
        <Table<ContractDetailDto>
          data={services}
          columns={columns}
          columnSelection={false}
          exportEnabled={false}
          enableMultiRowSelection
        />
      </QueryError>
    </div>
  );
};

export default ContractDetails;
