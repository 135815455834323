import { useState, useEffect } from 'react';
import { CountryType, CountryTypeMap } from 'utils/Interfaces/StaticData';
import { useStaticData } from './useStaticData';

const useEnums = () => {
  const [countryTypeEnum, setCountryTypeEnum] = useState<CountryTypeMap>({});

  const { data } = useStaticData(
    '/country/get-country-types',
    '/country/get-country-types',
    true
  );

  useEffect(() => {
    if (data?.data?.length) {
      const obj: CountryTypeMap = {};
      data.data.forEach((dataObj: CountryType) => {
        if (dataObj.name) {
          obj[dataObj.name] = dataObj;
        }
      });
      setCountryTypeEnum(obj);
    }
  }, [data]);

  return {
    countryTypeEnum
  };
};

export default useEnums;
