import { Card, Sizes } from '@sede-x/shell-ds-react-framework';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import DatePicker from 'components/FormCmponents/DatePicker';
import NumberField from 'components/FormCmponents/NumberField';
import dayjs from 'dayjs';
import { Field, FormikValues, getIn, useFormikContext } from 'formik';
import { KeyboardEvent, useEffect, useState } from 'react';
import { DEFAULT_DATE_FORMAT } from 'utils/helpers';
import { CurrencyDto } from './types';

const blockInvalidChar = (event: KeyboardEvent<HTMLInputElement>) => {
  ['e', 'E', '+', '-', 'ArrowDown', 'ArrowUp'].includes(event.key) &&
    event.preventDefault();
};

async function fetchDefaultNotUsedThreePlusMonthsFee(customerId?: string) {
  return customerInstance.post(t4eEndpoints.DefaultNotUsedThreePlusMonthsFee, {
    customerId
  });
}

interface FeeFormProps {
  type: number;
  customerId?: string;
}

const RentalFeeForm: React.FC<FeeFormProps> = ({ type, customerId }) => {
  const { setFieldValue, values } = useFormikContext<FormikValues>();
  const [currencyLabel, setCurrencyLabel] = useState<string | null>();

  const { data } = useQuery({
    queryKey: ['t4e-toll-discounts'],
    queryFn: () =>
      fetchDefaultNotUsedThreePlusMonthsFee(customerId).then((res) => res.data),
    refetchOnWindowFocus: false,
    enabled: type === 0 && !!customerId
  });

  const currencyData: CurrencyDto | undefined = data?.data;

  useEffect(() => {
    if (currencyData) {
      const { currency, fees } = currencyData;
      setCurrencyLabel(currency);
      setFieldValue('notUsedThreePlusMonthsFee', fees);
    } else {
      setCurrencyLabel(null);
      setFieldValue('notUsedThreePlusMonthsFee', undefined);
    }
  }, [currencyData]);

  const defaultRentId =
    type === 0 ? 'rentalFee.defalutValue' : 'rentalFeeNP.defalutValue';
  const fromId = type === 0 ? 'rentalFee.startDate' : 'rentalFeeNP.startDate';
  const toId = type === 0 ? 'rentalFee.endDate' : 'rentalFeeNP.endDate';
  const valueId = type === 0 ? 'rentalFee.fee' : 'rentalFeeNP.fee';

  return (
    <div className="grid grid-cols-12  gap-4 p-6">
      <div className="col-span-9">
        <Field
          id={defaultRentId}
          name={defaultRentId}
          placeholder="Type here"
          label={
            type === 0
              ? 'Default Rental Fee'
              : 'Default Rental Fee for Never Personalized OBUs'
          }
          onKeyDown={blockInvalidChar}
          labelPosition="left"
          component={NumberField}
          onChange={(e: KeyboardEvent<HTMLInputElement>) => {
            const { value } = e.target as HTMLInputElement;
            // if value is empty, reset the values
            if (!value) {
              const field = type === 0 ? 'rentalFee' : 'rentalFeeNP';
              setFieldValue(valueId, '');
              setFieldValue(field, undefined);
            } else {
              setFieldValue(defaultRentId, parseInt(value, 10));
            }
          }}
        />
        <div className="flex flex-col gap-6">
          <Card header={{ title: '' }} elevated={false} bodyBgColor>
            <Field
              id={fromId}
              name={fromId}
              placeholder="Type here"
              label="From Date"
              labelPosition="left"
              component={DatePicker}
              format={DEFAULT_DATE_FORMAT}
              onChange={(v: dayjs.Dayjs | null) => {
                setFieldValue(fromId, v?.toISOString());
              }}
              disabledDate={(current: dayjs.Dayjs) =>
                getIn(values, toId) &&
                current.isAfter(dayjs(getIn(values, toId)))
              }
              disabled={!getIn(values, defaultRentId)}
            />
            <Field
              id={toId}
              name={toId}
              placeholder="Type here"
              label="To Date"
              labelPosition="left"
              component={DatePicker}
              format={DEFAULT_DATE_FORMAT}
              onChange={(v: dayjs.Dayjs | null) => {
                setFieldValue(toId, v?.toISOString());
              }}
              disabledDate={(current: dayjs.Dayjs) =>
                getIn(values, fromId) &&
                current.isBefore(dayjs(getIn(values, fromId)))
              }
              disabled={!getIn(values, defaultRentId)}
            />
            <Field
              id={valueId}
              name={valueId}
              placeholder="Type here"
              label="Value"
              labelPosition="left"
              component={NumberField}
              onKeyDown={blockInvalidChar}
              disabled={!getIn(values, defaultRentId)}
            />
          </Card>
          {type === 0 && (
            <Card
              header={{ title: 'Not Used 3+ months Fee', border: true }}
              elevated={false}
              size={Sizes.Small}
            >
              <Field
                id="notUsedThreePlusMonthsFee"
                name="notUsedThreePlusMonthsFee"
                placeholder="Type here"
                label={`Not Used 3+ months Fee ${
                  currencyLabel ? `[${currencyLabel}]` : ''
                }`}
                labelPosition="left"
                onKeyDown={blockInvalidChar}
                step="any"
                component={NumberField}
              />
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default RentalFeeForm;
