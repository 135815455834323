import React, { ReactNode } from 'react';
import { BaseTable, Card, Sizes, Text } from '@sede-x/shell-ds-react-framework';
import OverviewColumns from 'components/Table/columns/overviewColumns.json';
import { FormikValues, useFormikContext } from 'formik';

const BoldText = ({ children }: { children: ReactNode }) => (
  <span className="font-bold">{children}</span>
);

const vehicleColumns = [
  ...OverviewColumns.VehicleList.map((column) => ({
    header: column.label,
    accessorKey: column.field
  }))
];
interface SummaryProps {
  title?: string;
}

const Summary: React.FC<SummaryProps> = () => {
  const { values } = useFormikContext<FormikValues>();

  const { vehicles, documentTypeName } = values || {};

  return (
    <div className="flex flex-col grow overflow-y-auto p-5 bg-shellExtraPaleGrey2">
      <div className="flex">
        <Text>
          <BoldText>Summery</BoldText>
        </Text>
      </div>
      <div className=" flex flex-col gap-4 p-6">
        {documentTypeName && (
          <Card
            header={{ title: 'Document Type' }}
            size={Sizes.Small}
            bodyBgColor
          >
            <Text size={Sizes.Small}>{documentTypeName}</Text>
          </Card>
        )}
        {vehicles && (
          <Card
            header={{ title: 'Selected Vehicles' }}
            size={Sizes.Small}
            bodyBgColor
          >
            <BaseTable columns={vehicleColumns} data={vehicles} />
          </Card>
        )}
      </div>
    </div>
  );
};

export default Summary;
