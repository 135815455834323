interface EndpointType {
  [key: string]: string;
}

interface ObjectEndpointType {
  [key: string]: EndpointType;
}

export const exportAllEndpoints: EndpointType = {
  exportAll: 'exportAll',
  SFH: 'exportAll/sfh',
  DuplicateTollDomainRegistration: 'exportAll/duplicatetolldomainregistration',
  telepassELCONReport: 'exportAll/elcon',
  slovakiaTransaction: 'exportall/slovakiatransaction'
};

export const actionButtonEndpoints: EndpointType = {
  germanyTransactionError: 'report/action/germany/transactionerror/deleted',
  germanyContractDeregistered:
    'report/action/germany/contractderegistered/block',
  t4eTransferObuExceptions: 'report/action/t4e/transferobuexceptions/add',
  insertCardChangeRequests:
    '/report/action/czechtollcard/insertcardchangerequests',
  czechTollTemplateDownload: 'report/action/czechtollcard/downloadtemplate',
  slovakiaTemplateDownload:
    'report/action/slovakiacardexpiry/changecards/downloadtemplate',
  slovakiaUploadTemplate:
    'report/action/slovakiacardexpiry/changecards/uploadtemplate',
  slovakiaCardChange: 'report/action/slovakiacardexpiry/changecard',
  czechUnknownContractsAssign: 'report/action/czechunknowncontracts/assign',
  slovakiaUnknownContractsAssign:
    'report/action/slovakiaunknowncontracts/assign',
  slovakiaUnknownContractsCustomer:
    'report/action/slovakiaunknowncontracts/customer'
};

const endpointConstants: EndpointType = {
  cardBlocked: 'card/cardsblocked',
  cardExpired: 'card/cardsexpired',
  contractList: 'contract/contractlist',
  transaction: 'transaction'
};
export const searchByTypeEndpoints: EndpointType = {
  Customer: 'customer/search-customer',
  Vehicle: 'vehicle/search-vehicle',
  Card: 'card/search-card',
  Contract: 'contracts/search-contract',
  OBU: 'obu/search-obu'
};

export const endpoints: EndpointType = {
  blacklist: 'blacklist',
  cardIssuerIncorrect: 'cardIssuerIncorrect',
  cardinuse: 'cardinuse',
  userActive: 'useractive',
  userError: 'user/usererror',
  cardExchange: 'cardexchange',
  lastCardExchange: 'lastCardExchange',
  cardIncorrectAssigned: 'cardincorrectassigned',
  customerRegistration: 'customerregistration',
  customerComments: 'customercomment',
  blockedCustomer: 'blockedCustomer',
  customerContacts: 'customercontact',
  customerStatus: 'customerStatus',
  reports: 'etollmanager',
  interfaceRunError: 'interfacerunerror',
  interfaceRuns: 'interfacerun',
  noVirtualCardActivated: 'novirtualcardactivated',
  duplicatedVehicles: 'duplicatedvehicle',
  vehicleWithCardsInfo: 'vehiclewithcardinformation',
  registeredAccountsOverview: 'registeredaccountsoverview',
  systemJobs: 'systemjob',
  SFH: 'sfh',
  customerCardOrderPending: 'gfn/gfncustomercardorderpending',
  userList: 'user/userlist',
  customerMissingMail: 'customermissingmail',
  gfncustomerCardOrder: 'gfn/customercardorder',
  gfncustomerCardOrderError: 'gfn/customercardordererror',
  gfntransaction: 'gfn/transaction',
  gfnVehicleCardOrderError: 'gfn/vehiclecardordererror',
  gfnVehicleCardOrder: 'gfn/vehiclecardorder',
  gfnExpiredCardReports: 'gfn/cardsexpired',
  gfnVirtualCardOrder: 'gfn/virtualcardorder',
  gfnCardExchangeHistory: 'gfn/cardexchangehistory',
  gfnOpenTransaction: 'gfn/opentransaction',
  gfnTransactionError: 'gfn/transactionerror',
  gfnMissingCardVehicle: 'gfn/missingcardvehicle',
  gfnCardBlocked: 'gfn/cardblocked',
  belgiumCardBlocked: endpointConstants.cardBlocked,
  belgiumCardExpired: endpointConstants.cardExpired,
  belgiumCardSyncErrors: 'card/cardsynchronizationerrors',
  belgiumTransaction: endpointConstants.transaction,
  belgiumContracts: 'contract',
  belgiumCustomerListReport: 'customer/customerlist',
  belgiumObusOnBlackList: 'obu/obusonblacklist',
  belgiumUnknownContracts: 'contract/unknowncontract',
  belgiumUnknownCountries: 'country/unknowncountries',
  telepassErrorReportOBU: 'error/obu',
  errorReportContract: 'error/contract',
  telepassCardBlocked: endpointConstants.cardBlocked,
  telepassCardExpired: endpointConstants.cardExpired,
  telepassOBUListReport: 'obu/obulist',
  telepassObuServiceListReport: 'obu/obuservicelist',
  telepassELCONReport: 'transaction/elcon',
  telepassOBUMonthlyRentalFees: 'obu/monthlyrentalfees',
  telepassOBUMonitoringReport: 'obu/monitoring',
  telepassContractList: endpointConstants.contractList,
  telepassOrderReport: 'SFH/telepassorder',
  ukLevyCardBlocked: endpointConstants.cardBlocked,
  ukLevyTransaction: endpointConstants.transaction,
  activeEvignettes: 'evignettes/activeevignettes',
  evignettesOpenOrders: 'order/openorders',
  evignettesOrders: 'order',
  telepassVehicleList: 'vehicle/vehiclelist',
  evignetteCardBlocked: endpointConstants.cardBlocked,
  evignetteCardExpired: endpointConstants.cardExpired,
  ukLevyCardExpired: endpointConstants.cardExpired,
  evignetteError: 'error',
  evignetteRegisteredCustomers: 'customer/eurovignetteregisteredcustomers',
  germanyDeregisteredbyCustomer: 'customer/deregisteredbycustomer',
  czechTollRegAccounts: 'customer/registeredaccounts',
  czechTollRegistrationRequests: 'customer/registerationrequest',
  czechTollCardChange: 'card/cardchangerequest',
  czechTollCardBlocked: 'card/cardsblocked',
  czechTollCardExpired: endpointConstants.cardExpired,
  czechTollExpiredCards: 'action/czechtollcard/expiredcards',
  czechTollUnknownContracts: 'action/czechunknowncontracts',
  germanyInvoice: 'transaction/transactioninvoice',
  germanyTransNotificationDaily: 'transaction/notification/daily',
  germanyNotificationMonthly: 'transaction/notification/monthly',
  germanyInboundOutbound: 'transaction/inboundoutbound',
  germanyContractList: endpointConstants.contractList,
  germanyContractStatus: 'contract/contractstatus',
  germanyLimitConfirmations: 'customer/limitconfirmation',
  germanyLimitConfirmationsErrors: 'error/limitconfirmation',
  germanyLimitRequests: 'customer/limitrequest',
  germanyTransactionError: 'error/transaction',
  germanyTransactionESI: 'transaction/esi',
  germanyTransactionGFN: 'transaction/gfn',
  germanytransNotificationFortnight: 'transaction/notification/fortnight',
  germanyTransReconciliation: 'transaction/reconciliation',
  germanyUninvoice: 'transaction/uninvoice',
  germanyPanIDMismatch: 'customer/panidmismatch',
  germanyUsingBlockedCard: 'card/usingblockedcard',
  germanyCardExpired: endpointConstants.cardExpired,
  germanyCardBlocked: endpointConstants.cardBlocked,
  slovakiaBlacklist: 'customer/blacklist',
  slovakiaContractList: endpointConstants.contractList,
  slovakiaCardBlocked: endpointConstants.cardBlocked,
  slovakiaCardExpired: endpointConstants.cardExpired,
  slovakiaExpiredCards: 'action/slovakiacardexpiry/getexpirecards',
  slovakiaCardChange: 'card/cardchange',
  slovakiaUnknownContracts: 'contract/unknowncontract',
  slovakiaActionsUnknownContracts: 'contract/slovakiaunknowncontract',
  slovakiaCardExpiryByFCE: 'card/cardexpirybyfce',
  slovakiaRejectedOrders: 'order/rejected',
  slovakiaErrors: 'error/errors',
  slovakiaRegistration: 'customer/registration',
  slovakiaUsingBlockedCard: 'card/usingblockedcard',
  slovakiaTransaction: 'transaction/slovakia',
  fdeCardBlocked: endpointConstants.cardBlocked,
  fdeCardExpired: endpointConstants.cardExpired,
  fdeCCLReport: 'customer/ccl',
  fdeRegistrationReport: 'customer/registration',
  fdeTransactionReport: endpointConstants.transaction,
  fdeVirtualCardOrder: 'card/virtualcardorder',
  t4eCardExpired: endpointConstants.cardExpired,
  t4eCardBlocked: endpointConstants.cardBlocked,
  t4eObuMonthlyRentalFees: 'obu/monthlyrentalfees',
  t4eCardPayerAssociationCodes: 'customer/cardpayerassociationcode',
  autopayCardBlocked: endpointConstants.cardBlocked,
  autopayCardExpired: endpointConstants.cardExpired,
  autoPayRegisteredVehiclesReport: 'vehicle/registration',
  autoPayVehicleRequestReport: 'vehicle/request',
  swizCardExpired: endpointConstants.cardExpired,
  swizCardBlocked: endpointConstants.cardBlocked,
  swizTransaction: endpointConstants.transaction,
  UnprocessedDeviceOrders: 'SFH/unprocesseddeviceorders',
  DuplicateTollDomainRegistration: 'SFH/duplicatetolldomainregistration',
  axxesOrder: 'SFH/axxesorder',
  germanyContractDeregistered: 'action/germany/contractderegistered',
  t4eTransferObuExceptions: 'action/t4e/transferobuexceptions',
  ShellConsortium: 'Consortium/membersandvehicles',
  evignetteRemove: 'action/evignette/customers'
};

export const customerEndPoints: EndpointType = {
  customerDetails: 'customer/get-customer-detail',
  customerConsortiumDetails: 'customer/get-customer-consortium-member-details',
  GFNDetails: 'customer/get-gfn-customer-details',
  addConsortiumMember: 'customer/add-customer-consortium-member',
  removeConsortiumMember: 'customer/remove-customer-consortium-member',
  saveCustomer: 'customer/save-customer',
  deleteCustomer: 'customer/delete-customer',
  obuDetails: 'obu/get-obudetails',
  addressList: 'address/address-list',
  addressDetails: 'address/get-address-detail',
  updateAddress: 'address/save-address',
  deleteAddress: 'address/delete-address',
  vehicleByCustomer: 'vehicle/get-vehicle-detail-by-customer',
  additionalAccounts: 'additionalaccount/search-additional-account',
  saveAdditionalAccount: 'additionalaccount/save-additional-account',
  deleteAdditionalAccount: 'additionalaccount/delete-additional-account',
  updateObu: 'obu/save-obu',
  deleteObu: 'obu/delete-obu',
  contractList: 'contracts/contract-list',
  contractDetails: 'contracts/contract-detail',
  deleteContract: 'contracts/delete-contract',
  saveContract: 'contracts/save-contract',
  searchObuByCustomer: 'obu/search-customerobus',
  searchObuByVehicle: 'obu/get-obu-by-vehicle',
  vehicleDetails: 'vehicle/get-vehicle-detail',
  saveVehicle: 'vehicle/save-vehicle',
  deleteVehicle: 'vehicle/delete-vehicle',
  contactDetails: 'contact/get-contact-detail',
  contactList: 'contact/search-contact',
  updateContact: 'contact/save-contact',
  deleteContact: 'contact/delete-contact',
  rentalPeriodList: 'contract-rental/period-list',
  saveRentalPeriod: 'contract-rental/save-period',
  cardList: 'card/card-list',
  cardDetails: 'card/card-detail',
  updateCard: 'card/save-card',
  deleteCard: 'card/delete-card',
  cardUsage: 'card/card-usage',
  getTollsForObu: 'obu/get-tolls-for-obu',
  getCustomerContract: 'obu/get-customer-contract',
  getVehiclesByCustomer: 'obu/get-vehicles-by-customer',
  obuGetFranceSubscriptions: 'obu/get-france-subscriptiontypes',
  obuGetFranceSubscriptionCheckboxes: 'obu/get-france-subscriptioncheckboxes',
  saveOBUFrance: 'obu/create-obu-france',
  vatIdCheck: 'customer/get-vatid-validity',
  kmasterContracts: 'obu/actions/telepass/get-kmaster-contracts',
  kmasterAddresses: 'obu/actions/telepass/get-kmaster-addresses',
  kmastervehicles: 'obu/actions/telepass/get-kmaster-vehicles',
  kmasterLanguages: 'obu/actions/telepass/get-kmaster-languages',
  kmasterObuTypes: 'obu/actions/telepass/get-kmaster-obutypes',
  savekmasterDetails: 'obu/actions/telepass/save-kmaster-details',
  getOBUProducts: 'obu/actions/t4e/get-Obu-products',
  getDeliveryProduct: 'obu/actions/t4e/get-type-products-by-country',
  getVehicleListForPersonalizeObu:
    'obu/actions/t4e/get-registered-vehicles-without-obu-for-personalize-obu',
  getVehicleListForOrderPersonalizeObu:
    'obu/actions/t4e/get-registered-vehicles-without-obu-for-order-and-personalize-obu',
  getOBUPersonalize: 'obu/actions/t4e/get-non-personalized-obu',
  saveOBUOrderAndNoPersonalize: 'obu/actions/t4e/save-obu-t4e-order',
  saveOBUOrderAndPersonalize: 'obu/actions/t4e/save-obu-t4e-order-personalize',
  saveT4ETransferObu: 'obu/actions/t4e/save-obu-t4e-transfer',
  saveOBUPersonalize: 'obu/actions/t4e/save-obu-t4e-personalize',
  getDepersonalizeOBUProduct: 'obu/actions/t4e/get-obu-model',
  saveOBUDepersonalize: 'obu/actions/t4e/save-obu-t4e-depersonalize',
  getContactListDepersonalize:
    'obu/actions/t4e/get-obu-toll-service-contract-data',
  saveT4ECompleteTransferObu: 'obu/actions/t4e/save-obu-t4e-complete-transfer',
  obuReplacementObuTypes: 'obu/actions/telepass/get-obu-replacement-obutypes',
  getStvVehicleService:
    'obu/actions/t4e/get-obu-vehicle-card-details-vehicleId',
  saveTerminateObu: 'obu/actions/t4e/save-obu-t4e-terminate'
};

export const t4eEndpoints: EndpointType = {
  customerDetails: 'customer/get-customer-detail',
  validate: 'customer/actions/t4e/validate-customer-registration',
  saveCustomerRegistration: 'customer/actions/t4e/save-customer-registration',
  getObuCompleteTransferVehicles:
    'customer/actions/t4e/get-vehicles-complete-registration',
  vehicleProducts: 'customer/actions/t4e/get-vehicle-products',
  TollDiscount: 'customer/actions/t4e/get-type-products-discount',
  saveCompleteRegistration:
    'customer/actions/t4e/save-customer-complete-registration',
  DefaultNotUsedThreePlusMonthsFee:
    'customer/actions/t4e/get-default-not-used-for-three-month-fee',
  validateCustomerUpdate: 'customer/actions/t4e/validate-customer-update',
  saveCustomerUpdate: 'customer/actions/t4e/save-customer-update',
  customerRequestHistory:
    'customer/actions/t4e/get-customer-master-request-history',
  customerRequestHistoryDetails:
    'customer/actions/t4e/get-customer-details-request-history',
  vehicleRequestHistory:
    'customer/actions/t4e/get-vehicle-master-request-history',
  vehicleRequestHistoryDetails:
    'customer/actions/t4e/get-vehicle-details-request-history',
  obuRequestHistory: 'customer/actions/t4e/get-obu-master-request-history',
  obuRequestHistoryDetails:
    'customer/actions/t4e/get-obu-details-request-history',
  parcelDetails: 'customer/actions/t4e/get-parcel-details-request-history',
  getUnregisteredVehicles: 'vehicle/actions/t4e/get-unregistered-vehicles',
  saveVehicleRegistration:
    'vehicle/actions/t4e/save-vehicle-registration-details',
  getVehiclePackDeclaration:
    'vehicle/actions/t4e/get-vehicles-pack-declaration',
  downloadVehiclePackDeclarationDocument:
    'vehicle/actions/t4e/download-vehicle-document-zip',
  getRegisteredVehiclesWithoutObu:
    'vehicle/actions/t4e/get-registered-vehicles-without-product',
  getVehicleConfigureProduct: 'vehicle/actions/t4e/get-vehicle-products',
  getVehiclesDeclaration: 'vehicle/actions/t4e/get-vehicles-declaration',
  saveDeclarationDocument: 'vehicle/actions/t4e/save-declaration-document',
  validateConfigureTollProduct:
    'vehicle/actions/t4e/validate-save-configure-toll-product-details',
  validateVehiclePartner: 'vehicle/actions/t4e/validate-vehicle-partner',
  saveConfigureTollProduct:
    'vehicle/actions/t4e/save-configure-toll-product-details',
  saveRegisterVehiclesAndConfigureProduct:
    'vehicle/actions/t4e/save-register-vehicles-and-configure-toll-product-details',
  getActiveObus: 'vehicle/actions/t4e/get-active-obus',
  validateObusPartnerVehicle:
    'vehicle/actions/t4e/validate-obus-partner-vehicle',
  getRestoredTollProduct: 'vehicle/actions/t4e/get-restore-toll-products',
  getRestoreTollProductSummary:
    'vehicle/actions/t4e/get-summary-restore-toll-products',
  saveRestoreTollProduct: 'vehicle/actions/t4e/save-restore-toll-product',
  getTollProduct: 'vehicle/actions/t4e/get-toll-products',
  getSummaryTollProduct: 'vehicle/actions/t4e/get-summary-toll-products',
  saveSuspendTollProduct: 'vehicle/actions/t4e/save-suspend-toll-product',
  saveTerminateTollProduct: 'vehicle/actions/t4e/save-terminate-toll-product',
  saveUpdateVehicleDetails: 'vehicle/actions/t4e/save-update-vehicle-details'
};

export const TollStatusEndpoints: EndpointType = {
  customer: 'customer/get-tolls-status',
  obu: 'obu/get-tolls-status',
  vehicle: 'vehicle/get-tolls-status',
  contract: 'contracts/get-tolls-status',
  contact: 'contact/get-tolls-status',
  card: 'card/get-tolls-status'
};

export const TollSpecficEndpoints: ObjectEndpointType = {
  get: {
    customer: 'customer/get-customer-toll-specific-data',
    obu: 'obu/get-toll-specific-details',
    vehicle: 'vehicle/get-toll-specific-details',
    contract: 'contracts/get-toll-specific-details',
    contact: 'contact/get-toll-specific-details'
  },
  update: {
    customer: 'customer/update-customer-toll-specific-data',
    obu: 'obu/update-toll-specific-details',
    vehicle: 'vehicle/update-toll-specific-details',
    contract: 'contracts/update-toll-specific-data',
    contact: 'contact/update-toll-specific-details'
  },
  delete: {
    customer: 'customer/delete-customer-toll-specific-data',
    obu: 'obu/delete-toll-specific-parameter',
    vehicle: 'vehicle/delete-toll-specific-parameter',
    contract: 'contracts/delete-contract-toll-specific-data',
    contact: 'contact/delete-toll-specific-parameter'
  }
};

export const CommentsEndpoints: ObjectEndpointType = {
  get: {
    customer: 'customer/get-customer-comments',
    obu: 'obu/get-obu-comments',
    vehicle: 'vehicle/get-vehicle-comments',
    contract: 'contracts/get-contract-comments',
    contact: 'contact/get-contact-comments',
    card: 'card/get-card-comments',
    address: 'address/get-address-comment'
  },
  save: {
    customer: 'customer/save-customer-comment',
    obu: 'obu/save-obu-comment',
    vehicle: 'vehicle/save-vehicle-comment',
    contract: 'contracts/save-contracts-comment',
    contact: 'contact/save-contact-comment',
    card: 'card/save-card-comment',
    address: 'address/save-address-comment'
  },
  delete: {
    customer: 'customer/delete-customer-comments-by-id',
    obu: 'obu/delete-obu-comments-by-id',
    vehicle: 'vehicle/delete-vehicle-comments-by-id',
    contract: 'contracts/delete-contract-comments-by-id',
    contact: 'contact/delete-contact-comments-by-id',
    card: 'card/delete-card-comments-by-id',
    address: 'address/delete-address-comments-by-id'
  }
};

export const LogsEndpoints: ObjectEndpointType = {
  get: {
    customer: 'customer/customer-logs',
    obu: 'obu/obu-logs',
    vehicle: 'vehicle/vehicle-logs',
    contract: 'contracts/contract-logs',
    contact: 'contact/contact-logs',
    card: 'card/card-logs',
    address: 'address/address-logs'
  }
};

export const CommentsByIdEndpoints: EndpointType = {
  customer: 'customer/get-customer-comments-by-id',
  obu: 'obu/get-obu-comments-by-id',
  vehicle: 'vehicle/get-vehicle-comments-by-id',
  contract: 'contracts/get-contract-comments-by-id',
  contact: 'contact/get-contact-comments-by-id',
  card: 'card/get-card-comments-by-id',
  address: 'address/get-address-comments-by-id'
};

export const DocumentsEndpoints: ObjectEndpointType = {
  get: {
    vehicle: 'vehicle/get-vehicle-documents',
    customer: 'customer/get-customer-documents'
  },
  delete: {
    vehicle: 'vehicle/delete-vehicle-document',
    customer: 'customer/delete-customer-document'
  },
  download: {
    vehicle: 'vehicle/download-vehicle-document',
    customer: 'customer/download-customer-document'
  },
  save: {
    vehicle: 'vehicle/upload-vehicle-document',
    customer: 'customer/upload-customer-document'
  }
};
