import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import QueryError from 'components/QueryError';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import React, { useState } from 'react';

interface RequestDetailsProps {
  customerId: string;
  requestHistoryId: string;
  type?: string;
}

const DEFAULT_PAGE_SIZE = 20;

const endPoint = {
  customer: t4eEndpoints.customerRequestHistoryDetails,
  vehicle: t4eEndpoints.vehicleRequestHistoryDetails,
  obu: t4eEndpoints.obuRequestHistoryDetails
};

const getTableColumns = (type: string) => {
  switch (type) {
    case 'customer':
      return getOverviewColumns('T4ECustomerRequestHistoryDetails');
    case 'vehicle':
      return getOverviewColumns('T4EVehicleRequestHistoryDetails');
    case 'obu':
      return getOverviewColumns('T4EVehicleRequestHistoryDetails');
    default:
      return getOverviewColumns('T4EVehicleRequestHistoryDetails');
  }
};

async function fetchRequestHistory(
  customerId: string,
  type: string,
  requestHistoryId: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post(endPoint[type as keyof typeof endPoint], {
    customerId,
    requestHistoryId,
    pageNumber,
    pageSize
  });
}

const RequestDetails: React.FC<RequestDetailsProps> = ({
  customerId,
  requestHistoryId,
  type = 'customer'
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const { data, isLoading, isError } = useQuery({
    queryKey: [
      't4e-CustomerRequest-Details',
      customerId,
      requestHistoryId,
      pageNumber,
      pageSize,
      type
    ],
    queryFn: () =>
      fetchRequestHistory(
        customerId,
        type,
        requestHistoryId,
        pageNumber,
        pageSize
      ).then((res) => res.data),
    refetchOnWindowFocus: false,
    enabled: !!requestHistoryId
  });

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);
  return (
    // <div className="p-5 bg-shellExtraPaleGrey3 h-full">
    <div
      className="flex flex-col grow overflow-y-auto gap-2 p-5 bg-shellExtraPaleGrey2 h-full "
      data-testid="request-detals-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
        <Table
          data={data?.data ?? []}
          columns={getTableColumns(type)}
          columnSelection={false}
          exportEnabled={false}
          enableRowSelection={false}
          enableMultiRowSelection={false}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
        />
      </QueryError>
    </div>
  );
};

export default RequestDetails;
