import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { FormikValues, useFormikContext } from 'formik';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import { useState, useEffect, forwardRef } from 'react';
import { t4eEndpoints } from 'api/apiEndpoints';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Flexbox, SelectionCheckbox } from '@sede-x/shell-ds-react-framework';
import { columnBuilder, renderActionTooltip } from 'utils/helpers';
import OverviewColumns from 'components/Table/columns/overviewColumns.json';
import _ from 'lodash';
import { VehicleListDto, VehicleRef } from '../../components/types';

const DEFAULT_PAGE_SIZE = 20;
interface VehicleListProps {
  customerId: string;
  documentType: string;
}

async function fetchVehicleList(
  customerId: string,
  documentType: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post(t4eEndpoints.getVehiclesDeclaration, {
    customerId,
    documentType,
    pageNumber,
    pageSize
  });
}

const columnHelper = createColumnHelper<VehicleListDto>();

const actions = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        {!!row.original.isEnabled && (
          <SelectionCheckbox
            checked={row.getIsSelected()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
            data-testid={`rowcheck-${row.index}`}
          />
        )}
      </Flexbox>
    ),
    header: ({ table }) => (
      <Flexbox gap="12px" alignItems="center">
        <SelectionCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      </Flexbox>
    )
  })
];

const VehicleList = forwardRef<VehicleRef, VehicleListProps>(
  ({ customerId, documentType }, _ref) => {
    const { setFieldValue } = useFormikContext<FormikValues>();
    const [selectedRows, setSelectedRows] = useState<VehicleListDto[]>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    const { data, isLoading, isError } = useQuery({
      queryKey: [
        'vehicle-for-complete-transfer',
        customerId,
        pageSize,
        pageNumber,
        documentType
      ],
      queryFn: () =>
        fetchVehicleList(customerId, documentType, pageNumber, pageSize).then(
          (res) => res.data
        ),
      keepPreviousData: true,
      enabled: !!documentType,
      refetchOnWindowFocus: false
    });

    const handleChangePagination = (
      newPageNumber: number,
      newPageSize: number
    ) => {
      setPageNumber(newPageNumber);
      setPageSize(newPageSize);
    };

    const paginationProps = useSdsPagination(handleChangePagination);

    useEffect(() => {
      const selectedVehicleIds = selectedRows.map((row) => ({
        vehicleId: row.vehicleId
      }));
      if (selectedRows.length) {
        setFieldValue('vehicles', selectedRows);
        setFieldValue('vehicleDetails', selectedVehicleIds);
      } else {
        setFieldValue('vehicles', undefined);
        setFieldValue('vehicleDetails', undefined);
      }
    }, [selectedRows]);

    return (
      <div
        className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
        data-testid="t4e-vehicle-list"
      >
        <QueryError isLoading={isLoading} isError={isError}>
          <Table<VehicleListDto>
            data={data?.data ?? []}
            columns={
              [
                ...actions,
                ...columnBuilder(OverviewColumns.VehicleList)
              ] as ColumnDef<VehicleListDto>[]
            }
            columnSelection={false}
            exportEnabled={false}
            enableMultiRowSelection
            enableRowSelection={(row) => !!row.original.isEnabled}
            meta={{
              getRowStyles: (row) => ({
                backgroundColor: row.original.isEnabled ? '' : '#DFDFDF'
              })
            }}
            paginationData={{
              ...paginationProps,
              total: data?.count
            }}
            getTooltip={(row) =>
              renderActionTooltip(row.original.validationMessage)
            }
            resetSelectedRowsOnPageChange={false}
            onSelectedRowsChange={(rows, rowSelectionState) => {
              setSelectedRows((prevSelectedRows) => {
                const allSelectedRows = [...prevSelectedRows, ...rows];
                const uniqueSelectedRows = _.uniqBy(
                  allSelectedRows,
                  'vehicleId'
                );
                return uniqueSelectedRows.filter(
                  (row) => rowSelectionState?.[row.vehicleId]
                );
              });
            }}
            getRowId={(row) => row.vehicleId}
          />
        </QueryError>
      </div>
    );
  }
);

export default VehicleList;
